.dropdown {
  margin-right: 10px;
  position: relative;
  display: inline-flex;
  border-radius: 7.5px;
  align-items: center;
  cursor: pointer;
  border: 3px solid rgba(255, 255, 255, 0.04);
  padding: 2.5px 12.5px;
  transition: background-color 0.125s ease-in-out, transform 0.125s ease-in-out;
  backdrop-filter: blur(5px);
  transform: scale(0.975);
  z-index: 1000;
}

.dropdown:hover {
  background-color: rgba(210, 210, 210, 0.15);
  transform: scale(1.01);
}

.dropdown-inner {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 7.5px;
}

.navbar-username {
  color: var(--text-color);
  margin-right: 15px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  user-select: none;
  object-fit: cover;
  white-space: nowrap;
}

.navbar-profile-pic {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  pointer-events: none;
  user-select: none;
  object-fit: cover;
}

.articleMoreRightSpace {
  margin-right: 12.5px;
}

.navbar-verified-pic {
  border-radius: 50%;
  width: 17.5px;
  height: 17.5px;
  margin-left: -8.5px;
  margin-right: 6.5px;
  pointer-events: none;
  user-select: none;
  align-items: center;
  object-fit: cover;
}

.dropdown-button {
  border: none;
  color: inherit;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.1s ease-in-out;
  backdrop-filter: blur(5px);
}

.dropdown-button:hover {
  background-color: rgba(236, 236, 236, 0.2);
}

.dropdown-content {
  border: 3px solid rgba(255, 255, 255, 0.1);
  display: none;
  position: fixed;
  top: calc(100% + 10px);
  left: 50%;
  background-color: rgba(12, 12, 12, 0.85);
  min-width: 145px;
  box-shadow: none;
  border-radius: 15px;
  padding: 11px;
  opacity: 0;
  transform: translateY(-10px) translateX(-50%);
  transition: opacity 0.3s ease, transform 0.3s ease;
  backdrop-filter: blur(5px);
  order: 1;
  z-index: 1000;
  font-weight: 700;
}

.dropdown:hover .dropdown-content {
  backdrop-filter: blur(5px);
  display: block;
  opacity: 1;
  transform: translateY(0) translateX(-50%);
}

.dropdown-content button:hover {
  transform: scale(1.05);
  background-color: rgba(250, 250, 250, 0.1);
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out;
}

.dropdown-name {
  font-weight: bold;
  padding-bottom: 5.5px;
}

.dropdown-content hr {
  border: 0;
  height: 1px;
  margin: 10px 0;
}

.dropdown-content button {
  color: inherit;
  padding: 13px 15px;
  text-decoration: none;
  display: block;
  border: none;
  background-color: transparent;
  width: 100%;
  text-align: left;
  border-radius: 10px !important;
  cursor: pointer;
}

.dropdown .dropdown-content {
  display: block;
}

.build-number {
  font-size: 0.75rem;
  color: #ffffff9c;
  text-align: right;
  margin-right: 4px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.dropdown button {
  display: flex;
  align-items: center;
}

.dropdown button img {
  width: 10px;
  height: 10px;
  transform: scale(2);
  margin-right: 15px;
  margin-left: 2.5px;
  pointer-events: none;
  user-select: none;
}

.dropdown-ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.ripple {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  width: 20px;
  height: 20px;
  pointer-events: none;
  transform: scale(0);
  animation: ripple 0.5s;
}

@keyframes ripple {
  to {
    transform: scale(10);
    opacity: 0;
  }
}

@media (max-width: 767px) {
  .dropdown-content {
    background-color: rgba(0, 0, 0, 0.7);
  }
}