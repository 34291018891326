.miniVideoCallPlayer {
  position: fixed;
  top: 70px;
  right: 1vw;
  background-color: rgb(20, 20, 20, 0.9);
  border: 3px solid rgba(255, 255, 255, 0.105);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border-radius: 20px;
  max-width: 375px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.musicTopSection {
  display: flex;
  align-items: center;
  gap: 15px;
}

.musicPlayer img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.track-info {
  display: flex;
  flex-direction: column;
}

.track-title {
  font-weight: 900;
  font-size: 20px;
  margin-bottom: 2px;
}

.track-artist {
  font-size: 13.5px;
  font-weight: 800;
  font-weight: 700;
}

.middleSectionMusicPlayer {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 7.5px;
}

.elapsed-time {
  margin: 0 10px;
  width: 35px;
}

.duration {
  margin: 0 10px;
  width: 35px;
}

.song-progress {
  flex-grow: 1;
}

.bottom-section {
  display: flex;
  justify-content: center;
  width: 100%;
}

.control-button {
  background-color: #1DB954;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.miniVideoCallProfilePicture {
  height: 50px;
  border-radius: 12.5px;
}

.miniVideoCallPlayer,
.localMiniVideo,
.videoWrapper {
  box-sizing: border-box;
}

.miniVideoCallPlayer .localMiniVideo {
  border-radius: 18.5px;
  width: 100%;
  margin-top: 12.5px;
}

.videoWrapper {
  border-radius: 18.5px;
  overflow: hidden;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.miniPlayer .song-progress {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  background: white;
  outline: none;
  opacity: 0.7;
  -webkit-transition: opacity .2s;
  transition: opacity .2s;
}

.miniPlayer .song-progress:hover {
  opacity: 1;
}

.miniPlayer .song-progress::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: black;
  border: 2px solid white;
  cursor: pointer;
  border-radius: 50%;
}

.miniPlayer .song-progress::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: black;
  border: 2px solid white;
  cursor: pointer;
  border-radius: 100%;
}

@media (max-width: 600px) {
  .miniVideoCallPlayer {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    width: 85vw;
  }
}