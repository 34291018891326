.profile {
  margin-left: 10px;
  padding: 2rem;
  min-height: 100vh;
}

.profile-container {
  background-color: #2c2c2c;
  border-radius: 10px;
  padding: 2rem;
  max-width: 800px;
}

.profile-info {
  display: flex;
  flex-direction: row;
  margin-bottom: -7.5px;
}

.verifiedPicture {
  width: 40px;
  height: 40px;
  margin-left: 12.5px;
  user-select: none;
  object-fit: cover;
}

@media (min-width: 767px) {
  .verifiedPicture {
    position: relative;
    display: inline-flex;
    border-radius: 7.5px;
    margin-bottom: -9px;
    padding: 5px 7px;
    transition: background-color 0.15s ease-in-out;
    transition: transform 0.1s ease-in-out;
  }

  .verifiedPicture:hover {
    background-color: rgba(250, 250, 250, 0.175);
    transform: scale(1.05);
  }
}

.usernameBox {
  display: flex;
  margin-top: 30px;
  font-weight: 900;
  font-size: 42px;
}

.blankUsernameBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.profileNote {
  color: rgba(255, 255, 255, 0.9);
  margin-top: 7px;
  font-size: 16px;
}

.countUI {
  margin-top: 5px;
  color: rgba(255, 255, 255, 0.85);
  margin-top: 7px;
  font-size: 20px;
  font-weight: 850;
}

.userName {
  margin-top: -2px;
  color: var(--usernameColor2);
  font-size: 14px;
  margin-bottom: 10px;
}

.profile-picture-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 2rem;
}

.profile-picture2 {
  margin-top: 25px;
  width: 185px;
  height: 185px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
  background-color: rgba(250, 250, 250, 0);
  transition: transform 0.075s ease-in-out;
}

.profile-picture2:hover {
  background-color: rgba(250, 250, 250, 0.175);
  transform: scale(1.03);
}

.profile-picture1 {
  margin-top: 25px;
  width: 185px;
  height: 185px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
}

.rate-limit-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  backdrop-filter: blur(2.5px);
  transform: translate(-50%, -50%);
  background-color: rgba(17, 17, 17, 0.95);
  color: white;
  padding: 25px;
  border-radius: 14px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.forceNoBackground {
  background-color: rgba(0, 0, 0, 0) !important;
}

.rateLimitPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  backdrop-filter: blur(2.5px);
  transform: translate(-50%, -50%);
  background-color: rgba(17, 17, 17, 0.95);
  border: 4px solid rgba(255, 255, 255, 0.1);
  color: white;
  padding: 20px;
  border-radius: 17.5px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rateLimitAdditonal {
  max-height: 60%;
  overflow-y: auto;
  overflow-x: hidden;
}

.rateLimitPopup img {
  width: 60px;
  margin-bottom: 12.5px;
  transition: transform 0.125s ease-in-out;
}

.rateLimitPopup img:hover {
  transform: scale(1.05);
}

.loadingFrame {
  height: 125px;
  backdrop-filter: blur(2.5px);
  background-color: rgba(17, 17, 17, 0.95);
  color: white;
  padding: 25px;
  border-radius: 14px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profileNote1 {
  margin-top: 10px;
}

.profileNote1:hover {
  margin-right: 0px;
}

.profile-note-input-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 10px;
}

.profile-note-input {
  width: 375px;
  padding: 1rem;
  margin-bottom: 1.05rem;
  border: 0px solid #ccc;
  border-radius: 6.5px;
  background-color: rgba(250, 250, 250, 0.175);
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  margin-right: 10px;
  color: white;
}

.update-note-button {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.update-note-button:hover {
  background-color: #43a047;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profileNote2 {
  margin-top: 6px;
  position: relative;
  display: inline-flex;
  border-radius: 7.5px;
  align-items: center;
  cursor: pointer;
  padding: 6px 10px;
  transition: background-color 0.15s ease-in-out;
  transition: transform 0.1s ease-in-out;
}

.socialIconStatusContainer {
  display: flex;
  align-items: center;
  gap: 11px;
  flex-wrap: wrap;
}

.smallTM {
  margin-top: 7px;
}

.socialStatusContainer {
  margin-top: 7px;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.topProfileSocialStatusContainer {
  margin-top: 7px;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.socialStatusContainerV3 {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.boldNumber {
  font-weight: 700;
  margin-right: 5px;
}

.lboldNumber {
  font-weight: 700;
  margin-left: 5px;
}

.socialStatus {
  user-select: none;
  justify-content: flex-start;
  font-family: 'Montserrat', sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  position: relative;
  display: inline-flex;
  border-radius: 7.5px;
  align-items: center;
  cursor: pointer;
  padding: 7.5px 9.5px;
  text-shadow: rgb(20, 20, 20) 0 0 15px;
  background-color: rgba(110, 110, 110, 0.2);
  transform: scale(1);
  margin-bottom: 2px;
  transition: transform 0.1s ease-in-out;
  height: 19px;
  outline: 2px solid rgba(255, 255, 255, 0.11);
  outline-offset: -2px;
  letter-spacing: 0.1px;
}

.topProfileSocialStatus {
  user-select: none;
  justify-content: flex-start;
  font-family: 'Montserrat', sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  position: relative;
  display: inline-flex;
  border-radius: 7.5px;
  align-items: center;
  cursor: pointer;
  padding: 7.5px 9.5px;
  text-shadow: rgb(20, 20, 20) 0 0 15px;
  background-color: rgba(110, 110, 110, 0.2);
  transform: scale(1);
  margin-bottom: 2px;
  transition: transform 0.1s ease-in-out;
  height: 19px;
  outline: 2px solid rgba(255, 255, 255, 0.11);
  outline-offset: -2px;
  letter-spacing: 0.1px;
}

.disableBorderRadius {
  border-radius: 0 !important;
}

.socialStatusText {
  pointer-events: none;
  user-select: none;
  object-fit: cover;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.socialIcon {
  height: 90%;
  margin-right: 8px;
  pointer-events: none;
  user-select: none;
  object-fit: cover;
}

.blackSocialIcon {
  filter: invert(100%);
  height: 90%;
  margin-right: 8px;
  pointer-events: none;
  user-select: none;
  object-fit: cover;
}

.semiRoundProfileImage {
  border-radius: 17.5px;
}

.socialIconV2 {
  object-fit: cover;
  height: 90%;
  margin-right: 8px;
  filter: brightness(0);
  pointer-events: none;
  user-select: none;
}

.modifyBG {
  margin-top: -13.5px;
  color: #ffffff !important;
  font-weight: 700 !important;
}

.greenBG {
  background-color: rgba(37, 185, 91, 0.65) !important;
}

.opacueBG {
  background-color: rgba(110, 110, 110, 0.45) !important;
}

.dullProfileButton {
  background-color: rgba(255, 255, 255, 0.65) !important;
}

.dullProfileButton:hover {
  transform: scale(1) !important;
}

.widerSocialIcon {
  height: 80%;
  margin-right: 8px;
  pointer-events: none;
  user-select: none;
  object-fit: cover;
}

.trackBackground {
  background-image: linear-gradient(to right, rgba(253, 73, 73, 0.9), rgba(253, 73, 73, 0.45));
  font-weight: 750;
}

.xcBackground {
  background-image: linear-gradient(to right, rgba(0, 81, 255, 0.9), rgba(255, 230, 0, 0.55));
  font-weight: 750;
}

.wrestlingBackground {
  background-image: linear-gradient(to right, rgba(211, 14, 14, 0.55), rgba(255, 127, 0, 0.55), rgba(255, 255, 0, 0.5), rgba(0, 255, 0, 0.55), rgba(0, 0, 255, 0.55), rgba(75, 0, 130, 0.55), rgba(148, 0, 211, 0.55));
  font-weight: 750;
}

.fbBackground {
  background-image: linear-gradient(to right, rgba(246, 170, 30, 0.9), rgba(246, 170, 30, 0.55));
  font-weight: 750;
}

.vbBackground {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.55), rgba(255, 255, 255, 0.4));
  font-weight: 750;
}

.bbBackground {
  background-image: linear-gradient(to right, rgba(246, 167, 30, 0.9), rgba(246, 167, 30, 0.55));
  font-weight: 750;
}

.soccerBackground {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.55), rgba(255, 255, 255, 0.4));
  font-weight: 750;
}

.baseballBackground {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.55), rgba(255, 255, 255, 0.4));
  font-weight: 750;
}

.clearSocialStatus {
  background-image: rgba(0, 0, 0, 0);
  font-weight: 750;
}

.deepBlueSocialStatus {
  background-color: rgb(0, 85, 160);
  font-weight: 750;
}

.robloxFriendSocialStatus {
  background-color: rgb(0, 120, 55);
  font-weight: 750;
}

.discordSocialStatus {
  background-image: linear-gradient(to right, rgba(88, 101, 242, 0.9), rgba(88, 101, 242, 0.5));
  font-weight: 750;
}

.twitterSocialStatus {
  background-image: linear-gradient(to right, rgba(30, 160, 240, 0.9), rgba(30, 160, 240, 0.55));
  font-weight: 750;
}

.brownSolidSocialStatus {
  background-image: linear-gradient(to right, rgba(18, 18, 18, 1), rgba(18, 18, 18, 1));
  font-weight: 750;
}

.snapchatSocialStatus {
  background-image: linear-gradient(to right, rgba(178, 175, 0, 0.75), rgba(255, 252, 0, 0.7));
  font-weight: 750;
}

.spotifySocialStatus {
  background-image: linear-gradient(to right, rgba(30, 200, 90, 0.9), rgba(30, 200, 90, 0.55));
  font-weight: 750;
}

.robloxSocialStatus {
  background-image: linear-gradient(to right, rgba(118, 118, 118, 0.9), rgba(118, 118, 118, 0.55));
  font-weight: 750;
}

.fortniteSocialStatus {
  background-image: linear-gradient(to right, rgba(0, 38, 205, 0.8), rgba(240, 98, 215, 0.55));
  font-weight: 750;
}

.instagramSocialStatus {
  background-image: linear-gradient(to left, rgba(131, 58, 180, 0.9), rgba(255, 120, 50, 0.65));
  font-weight: 750;
}

.heartSocialStatus {
  background-image: linear-gradient(to right, rgb(213, 25, 36), rgba(213, 23, 36, 0.8));
  color: #ffffff !important;
  font-weight: 750;
}

.add3pxBorder {
  border: 3px solid rgba(255, 255, 255, 0.1);
}

.socialStatus:hover {
  transition: transform 0.1s ease-in-out;
  transform: scale(1.035);
}

.topProfileSocialStatus:hover {
  transition: transform 0.1s ease-in-out;
  transform: scale(1.035);
}


.robloxLimitedPic {
  width: 75px;
  height: 75px;
  object-fit: cover;
}

.robloxLimitedName {
  object-fit: cover;
  text-align: center;
  font-size: 16px;
  max-width: 90%;
  font-weight: 900;
  overflow: hidden;
  text-overflow: ellipsis;
}

.goldColor {
  color: rgba(231, 174, 18, 0.8) !important;
}

.additionalLetterSpacing {
  letter-spacing: 0.5px;
}

.maxHeightRobloxModal {
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.centerGridSki {
  justify-content: center !important;
}

.limitedsDiv {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.limitedsCard {
  background-color: rgba(65, 65, 65, 0.7) !important;
  border-radius: 10px;
  padding: 10px 5px 5px 5px;
  display: flex;
  flex-direction: column;
  gap: 2.5px;
  align-items: center;
  justify-content: flex-start;
  width: 190px;
  transition: background-color 0.125s ease-in-out, transform 0.125s ease-in-out;
}

.limitedsCard:hover {
  transition: transform 0.1s ease-in-out;
  background-color: rgb(85, 85, 85) !important;
  transform: scale(1.04);
}

.boostSocialIcon {
  height: 110% !important;
}

.goBackPopup {
  font-weight: 700;
  position: reltive;
  display: inline-flex;
  border-radius: 7.5px;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  padding: 4px 7px;
  background-color: rgba(255, 255, 255, 0.95);
  margin-top: 10px;
  margin-bottom: -5px;
  transition: transform 0.1s ease-in-out;
  color: #2c2c2c;
  height: 25px;
}

.goBackPopup:hover {
  transform: scale(1.035);
}

.socialStatusV2 {
  font-weight: 700;
  position: reltive;
  display: inline-flex;
  border-radius: 7.5px;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  padding: 6px 9px;
  background-color: rgba(255, 255, 255, 0.95);
  margin-bottom: 2.5px;
  transition: transform 0.1s ease-in-out;
  color: #2c2c2c;
  height: 25px;
}

.socialStatusV2:hover {
  transform: scale(1.035);
}

.friendButton {
  margin-top: 22px;
  margin-left: 2px;
  position: relative;
  display: inline-flex;
  border-radius: 7.5px;
  align-items: center;
  cursor: pointer;
  padding: 6.5px 9.5px;
  transition: background-color 0.15s ease-in-out;
  transition: transform 0.1s ease-in-out;
  font-weight: 900;
  font-size: 18px;
  margin-bottom: -12px;
  letter-spacing: 0.2px;
}

.friendButton:hover {
  background-color: rgba(250, 250, 250, 0.175);
  transform: scale(1.025);
}

.profileNote2:hover {
  background-color: rgba(250, 250, 250, 0.175);
  transform: scale(1.025);
}

.rate-limit-popup img {
  width: 80px;
  margin-bottom: 15px;
  transition: transform 0.1s ease-in-out;
}

.rate-limit-popup img:hover {
  transform: scale(1.05);
}

.connectinImg {
  margin-top: 10px;
  width: 300px !important;
  height: initial !important;
  margin-bottom: 15px;
  transition: transform 0.1s ease-in-out;
}

.videoLoader {
  height: 175px;
  transform: scale(1.1);
  transition: transform 0.125s ease-in-out;
}

.videoLoader:hover {
  transform: scale(1.2);
  pointer-events: none;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.ratelimit-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 700;
}

.ratelimit-title {
  color: rgba(255, 255, 255, 1);
  font-size: 25px;
  font-weight: 900;
  margin-bottom: 10px;
  text-align: center;
}

.loading-title {
  margin-top: 5px;
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-weight: 800;
  margin-bottom: -10px;
}

.ratelimit-message {
  color: var(--usernameColor2);
  font-size: 14px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 15px;
}

.ratelimit-message2 {
  color: var(--usernameColor2);
  font-size: 14px;
  text-align: center;
  font-weight: 700;
}

.file-input {
  margin-bottom: 1rem;
}

.smallMarginTop {
  margin-top: 15px;
}

.upload-button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.friendSocialCard {
  display: flex;
  justify-items: center;
  flex-direction: column;
  align-items: center;
  background-color: rgba(17, 17, 17, 0.95);
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-radius: 22.5px;
  padding: 10px;
  width: 10vw;
  height: 8vw;
  transition: transform 0.1s ease-in-out;
  animation: popIn 0.1s ease-out;
}

.aboutMeText {
  margin-top: 23px;
  border-radius: 10px;
  cursor: pointer;
  padding: 6.5px 11px;
  max-width: 65%;
  background-color: var(--seventeenBackGroundColor);
  border: 3px solid rgba(45, 45, 45, 1);
  position: relative;
  display: inline-block;
  transition: background-color 0.125s ease-in-out, color 0.125s ease-in-out, transform 0.125s ease-in-out, border 0.125s ease-in-out;
}

.parentOfAboutMeText {
  align-items: center;
  justify-content: center;
}

.friend-profile-info {
  display: flex;
  flex-direction: column;
}

.friend-verifiedPicture {
  user-select: none;
  object-fit: cover;
  position: relative;
  display: inline-flex;
  border-radius: 7.5px;
  padding: 0.15vw 0.15vw;
  transition: background-color 0.15s ease-in-out;
  transition: transform 0.1s ease-in-out;
  margin-top: -3px;
  margin-right: -5px;
  width: 1.1vw;
  height: 1.1vw;
  margin-bottom: -10px;
  margin-left: 5px;
  margin-bottom: 0;
  margin-top: 0;
}

.friend-verifiedPicture:hover {
  background-color: rgba(250, 250, 250, 0.175);
  transform: scale(1.025);
}

.extraMarginTop {
  margin-top: 10px;
}

.miniFriendInfoBox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.friend-profile-username {
  margin-top: 1px;
  text-align: center;
  font-size: 0.65vw;
  color: rgba(255, 255, 255, 0.5);
}

.friend-profile-name {
  user-select: none;
  text-align: center;
  font-size: 0.75vw;
  font-weight: 900;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.friendProfilePic {
  pointer-events: none;
  user-select: none;
  object-fit: cover;
  margin-top: 10px;
  width: 4vw;
  height: 4vw;
  border-radius: 27.5%;
  object-fit: cover;
  margin-bottom: 10px;
}

.profilePictureZoomModail {
  width: 50vw;
}

.profilePictureZoom {
  height: 20vw;
  width: 20vw;
}

.friendSocialCard:hover {
  background-color: rgba(193, 193, 193, 0.25);
  transform: scale(1.05);
}

.upload-button:hover {
  background-color: #43a047;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.dropdown-icon span {
  width: 100%;
  height: 2px;
  background-color: #bdc3c7;
  margin-bottom: 4px;
}

.dropdown-icon span:last-child {
  margin-bottom: 0;
}

.lockModalMaxWidth {
  max-width: 20vw;
}

@media (max-width: 1500px) {
  .friendSocialCard {
    width: 9.4vw;
    height: 9.4vw;
    border-radius: 20px;
  }

  .lockModalMaxWidth {
    max-width: inherit;
  }

  .aboutMeText {
    max-width: 85%;
  }

  .friendProfilePic {
    width: 5vw;
    height: 5vw;
  }

  .friend-profile-name {
    font-size: 0.75vw;
  }

  .friend-verifiedPicture {
    width: 1vw;
    height: 1vw;
  }
}

@media (max-width: 767px) {
  .topProfileSocialStatusContainer {
    gap: 1.7px;
    margin-top: inherit;
    justify-content: flex-start;
  }

  .profile-picture1 {
    width: min(115px, 27vw);
    height: min(115px, 27vw);
  }

  .profile-picture2 {
    width: min(115px, 27vw);
    height: min(115px, 27vw);
  }

  .profile-info {
    margin-bottom: 0px;
    padding: 0 15px;
  }

  .profile-details {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 10px;
  }

  .topProfileSocialStatus {
    display: flex;
    min-width: auto;
    margin-bottom: 9px;
    transform: scale(1);
    font-size: min(15.5px, 3.55vw);
    padding: 7.5px 9.5px;
    background-color: rgba(110, 110, 110, 0);
    outline: 0px;
    flex-direction: column;
    height: inherit;
  }

  .usernameBox {
    margin-top: 1rem;
    font-size: min(25px, 6vw)
  }

  .topProfileSocialStatus:hover {
    transform: scale(1.035);
  }

  .friendButton {
    display: flex;
    justify-content: center;
    font-size: 17.5px;
    margin-bottom: -15px;
  }

  .friend-profile-username {
    font-size: 0.65vw;
  }

  .friendSocialCard {
    width: 40vw;
    height: inherit !important;
    border-radius: 22.5px;
  }

  .topLinenInfo {
    width: inherit !important;
  }

  .socialModailText {
    font-size: 7.5vw !important;
  }

  .modalBigText {
    font-size: 7.5vw !important;
  }

  .modalSubText {
    font-size: 3.25vw !important;
  }

  .smallSocialModailText {
    font-size: 3.25vw !important;
  }

  .lessSmallerSocialModailText {
    font-size: 4vw !important;
  }

  .parentOfAboutMeText {
    text-align: center;
    justify-self: center;
    justify-content: center;
    align-items: center;
  }

  .mobileTopProfile {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }

  .aboutMeText {
    max-width: 85%;
  }

  .meherSocialButton {
    font-size: 2vw !important;
  }

  .topModalContainerPicture {
    height: 10vw !important;
    width: 10vw !important;
  }

  .statusModail {
    width: 85vw !important;
  }

  .profilePictureZoomModail {
    width: 80vw;
  }

  .profilePictureZoom {
    height: 75vw !important;
    width: 75vw !important;
  }

  .messageModal {
    width: 80vw !important;
  }

  .friendProfilePic {
    margin-top: 3px !important;
    width: 17.5vw;
    height: 17.5vw;
  }

  .friend-profile-name {
    font-size: 3.65vw;
    letter-spacing: 0.1px;
  }

  .friend-profile-username {
    font-size: 2.5vw;
  }

  .friend-verifiedPicture {
    margin-left: 7.5px;
    width: 5vw;
    height: 5vw;
  }

  .profile {
    margin-left: 0px;
    padding: 0px;
    width: 100%;
    padding-top: 1rem;
  }

  .rate-limit-popup {
    width: 80%;
  }

  .rate-limit-popup img {
    width: 100px;
    height: 100px;
  }

  .profile-picture-container {
    margin-right: 0;
    margin-bottom: -1rem;
  }

  .meherSocialButton {
    font-size: 4vw !important;
    padding: 1.25vw 1.5vw !important;
  }

  .verifiedPicture {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    user-select: none;
    cursor: pointer;
    object-fit: cover;
    pointer-events: none;
    margin-bottom: -8px;
    margin-right: 2.5px;
  }

  .verifiedPicture:hover {
    background-color: rgba(250, 250, 250, 0.175);
    transform: scale(1.025);
  }

  .socialStatusContainer {
    justify-content: center;
    gap: 7px;
  }

  .socialStatusContainerV3 {
    justify-content: center;
    gap: 7px;
    margin-top: 10px;
    margin-bottom: -10px;
  }

  .mobileStatusInherit {
    justify-content: inherit !important;
  }

  .socialIconStatusContainer {
    justify-content: center;
    gap: 7.5px;
  }

  .socialStatusV2 {
    margin-bottom: 1px;
  }

  .socialStatus {
    min-width: auto;
    margin-bottom: 9px;
    transform: scale(1);
  }

  .socialStatus:hover {
    transform: scale(1.035);
  }

  .profile-note-input-container {
    display: flex;
    justify-content: center;
    margin: 10px auto 0;
    width: 200px;
  }

  .limitedsCard {
    width: 145px;
  }

  .profileNote1 {
    margin-top: 5px;
    margin-bottom: 4px;
    max-width: 90%;
    width: 80%;
    font-size: 15px;
    transition: margin 0.1s ease-in-out;
  }

  .profileNote2 {
    margin-top: 0;
    margin-bottom: 4px;
    max-width: 90%;
    width: 80%;
    font-size: 15px;
    transition: margin 0.1s ease-in-out;
  }

  .userName {
    margin-top: 1px;
    color: var(--usernameColor2);
    font-size: 12.5px;
  }
}