.game-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  width: 25.3125vw;
  height: 12.6vw;
  border: none;
  color: white;
  position: relative;
  overflow: hidden;
  border-radius: 0.8vw;
  margin-top: 0.5vw;
  margin-bottom: 0.5vw;
  transition: background-color 0.125s ease-in-out, transform 0.1s ease-in-out;
  animation: popIn 0.15s ease-out;
  transform: scale(0.99);
}

.limitedIcon {
  height: 125px;
  width: 125px;
}

.robloxGamesGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.react-multi-carousel-item {
  margin: 0 0.5vw !important;
  width: inherit !important;
}

.sortTitle {
  place-items: center;
  user-select: none;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
  position: relative;
  display: inline-flex;
  border-radius: 9px;
  align-items: center;
  cursor: pointer;
  padding: 3px 10px;
  background-color: rgba(50, 50, 50, 0.2);
  transform: scale(1);
  transition: transform 0.1s ease-in-out;
  font-weight: 750;
  border: 3.5px solid rgba(255, 255, 255, 0.15);
  color: #ffffff;
  font-size: 20px;
  font-weight: 800;
  margin-top: 0.5vw;
  margin-left: 17.5px;
}

.sortTitle:hover {
  transition: transform 0.1s ease-in-out;
  transform: scale(1.035);
}

.game-button:hover {
  transform: scale(1.035);
}

.game-mini-information {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 92.5%;
  gap: 0.75vw;
  margin-bottom: 0.3vw;
}

.mini-information-piece {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4vw;
}

.gameDetailsContentRows {
  display: flex;
  flex-direction: column;
}

.playButtonRight {
  display: flex;
  justify-content: flex-end;
  margin-right: 2.75vw;
  margin-bottom: 15px;
  gap: 7.5px;
}

.mini-info-text {
  font-size: 0.7vw;
  letter-spacing: 0.025vw;
  font-weight: 700;
  color: var(--usernameColor2);
  justify-self: center;
}

.info-icon {
  height: 0.95vw;
  opacity: 0.8;
}

.noOpacity {
  opacity: 1;
}

.gameDetailsContent {
  display: flex;
  flex-direction: row;
  gap: 0.75vw;
}

.gameDetailsGameDescription {
  max-width: 40%;
  max-height: 14vw;
  text-align: left;
  overflow: auto;
  padding-right: 5px;
}

.eyeIconRobloxGames {
  width: 1.1vw;
  height: 1.15vw;
}

.robloxGameDetailsModail {
  display: flex;
  flex-direction: column;
  width: 60vw;
  padding: 0vw 1vw;
  max-height: 75vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.gameDetailsGameName {
  font-size: 2.25vw;
  margin-top: 0.45vw;
  margin-left: 0.25vw;
  margin-bottom: 0.25vw;
  font-weight: 900;
}

.aboutMeTextHover {
  background-color: rgba(17, 17, 17, 0.95);
}

.aboutMeTextHover:hover {
  background-color: rgba(50, 50, 50, 1);
  border: 3px solid rgba(50, 50, 50, 1) !important;
  transform: scale(1.055);
}

.aboutMeTextClick {
  background-color: rgba(255, 255, 255, 0.9) !important;
  border: 3px solid rgba(255, 255, 255, 0.1) !important;
  color: rgba(17, 17, 17, 0.95);
}

.smallInnerPaddingForModal {
  padding: 5px;
}

.gameDetailsGameDescription {
  font-size: 1vw;
  margin-top: 0.25vw;
  margin-left: 0.5vw;
  margin-bottom: 0.25vw;
  font-weight: 700;
  max-width: 100%;
  color: rgba(255, 255, 255, 0.85);
}

.gameDetailsSmallDetails {
  display: flex;
}

.gameDetailsAuthor {
  margin-top: 0.15vw;
  margin-right: 0.5vw;
  position: relative;
  display: inline-flex;
  border-radius: 0.45vw;
  align-items: center;
  cursor: pointer;
  padding: 0.175vw 0.6vw;
  border: 3px solid rgba(255, 255, 255, 0.04);
  transition: background-color 0.125s ease-in-out, transform 0.125s ease-in-out;
  backdrop-filter: blur(5px);
  flex-direction: row-reverse;
}

.gameDetailsAuthor:hover {
  background-color: rgba(210, 210, 210, 0.1);
  transform: scale(1.015);
}

.gameDetailsThumbnail {
  flex: 1 0 50%;
  max-width: 50%;
  margin-top: 0.75vw;
  width: 27.5vw;
  height: 100%;
  object-fit: cover;
  border-radius: 0.75vw;
  transform: scale(1.01)
}

.game-thumbnail {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  border-radius: 0.95vw;
  transform: scale(1.01)
}

.left-arrow:hover {
  transform: translateY(-50%) scale(1.62);
}

.gameDetailsInfo {
  margin-left: 0.25vw;
  color: white;
  z-index: 2;
  display: flex;
  flex-direction: column-reverse;
}

.game-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 7.5vw;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  z-index: 2;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.98));
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  padding: 0.5em;
}

.closeArrow {
  padding: 0 !important;
  font-size: 0 !important;
  display: none !important;
}

.game-votes {
  display: block;
  text-align: center;
}

.leftSearchContainer {
  justify-content: left !important;
  margin-left: 10px;
}

.smallIcon {
  width: 0%;
  position: absolute;
  bottom: 1vw;
  right: 1vw;
  object-fit: cover;
  transform: scale(1.01);
}

.customArrow {
  transform: translateY(-50%);
  transition: transform 0.1s ease-in-out;
  border: 4px solid rgba(255, 255, 255, 0.5);
}

.customArrow:hover {
  transform: translateY(-50%) scale(1.15);
}

.game-name {
  color: rgba(255, 255, 255, 0.9);
  position: relative;
  display: flex;
  text-align: left;
  align-items: flex-end;
  width: 92.5%;
  font-size: 1.75vw;
  z-index: 1;
  font-weight: 800;
  margin-bottom: 0.5vw;
}

.games {
  justify-content: center;
  align-self: center;
  align-items: center;
  display: grid;
  gap: 50px;
}

.robloxGameHorizontalDiv {
  display: flex;
  flex-direction: row;
  gap: 0.7vw;
  align-items: center;
  justify-content: center;
}

.gameMoreInfoDiv {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 1.25vw;
}

.robloxGameMoreInfoIcon {
  height: 2.2vw;
  width: 2.2vw;
}

.robloxGameMoreInfoText1 {
  text-align: left;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.75vw;
}

.robloxGameMoreInfoText2 {
  text-align: left;
  font-weight: 800;
  font-size: 1.1vw
}

@media (max-width: 850px) {
  .game-button {
    width: 75.95vw;
    height: 38vw;
    border-radius: 10px;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
  }

  .maxHeightTextMobile {
    max-height: inherit !important;
  }

  .gameDetailsGameDescription {
    font-size: 3.25vw;
  }

  .react-multi-carousel-item {
    margin: 0 29vw 0 0;
  }

  .robloxGameDetailsModail {
    width: 80vw;
  }

  .gameDetailsContent {
    flex-direction: column;
  }

  .mini-info-text {
    font-size: 15px;
  }

  .gameDetailsGameName {
    font-size: 30px;
  }

  .mini-information-piece {
    gap: 3px;
  }

  .info-icon {
    height: 16px;
    width: 16px;
  }

  .eyeIconRobloxGames {
    height: 20px !important;
    width: 20px !important;
  }

  .game-mini-information {
    flex-wrap: wrap;
    gap: 10px;
  }

  .gameDetailsGameDescription {
    text-align: center;
    max-height: 50vw;
  }

  .gameDetailsContent {
    margin-top: 7.5px;
  }

  .gameDetailsThumbnail {
    max-width: inherit !important;
    width: 75vw !important;
    height: 90% !important;
    border-radius: 12.5px;
    align-self: center;
  }

  .game-button:hover {
    transform: scale(0.99) !important;
  }

  .playButtonRight {
    margin-right: inherit;
    margin-top: 2.5px;
  }

  .akrRBLXShopButton {
    font-size: 20px;
    padding: 8px 12.5px !important;
  }

  .game-name {
    font-size: 25px;
  }

  .gameDetailsInfo {
    margin-bottom: 10px;
  }

  .robloxGameHorizontalDiv {
    gap: 2vw;
  }

  .gameMoreInfoDiv {
    margin-top: 10px;
    gap: 5vw;
  }

  .robloxGameMoreInfoIcon {
    height: 9vw;
    width: 9vw;
  }

  .robloxGameMoreInfoText1 {
    font-size: 3vw;
  }

  .robloxGameMoreInfoText2 {
    font-size: 4.4vw
  }

  .gameDetailsSmallDetails {
    justify-content: center;
  }
}