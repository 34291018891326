.loggedOutDashboardTopBar {
    margin-top: 0.4vw;
    display: flex;
    justify-content: center;
    align-content: center;
    gap: 0.4vw;
}

.loggedOutDashboardMain {
    display: flex;
    justify-content: center;
    flex-direction: column;
    overflow-x: hidden;
}

.loggedOutDashboard {
    justify-content: center;
    justify-self: center
}

.smallLoggedOutTopbarButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(20, 20, 20, 0.9);
    border: none;
    padding: 0.22vw 0.53vw;
    border-radius: 0.75vh;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    text-decoration: none;
    transform: scale(1);
    border: 2px solid rgba(255, 255, 255, 0.105);
    transition: background-color 0.15s ease-in-out, transform 0.1s ease-in-out;
    animation: popIn 0.175s ease-out;
}

.loggedOutTopbarTopLabel {
    user-select: none;
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-self: center;
    gap: 0.4vw;
}

.loggedOutTopbarTopSignUpHelp {
    user-select: none;
    align-self: center;
    justify-self: center;
    margin-top: 0.35vw;
    font-size: 0.7vw;
    color: rgba(255, 255, 255, 0.85);
}

.loggedOutTopbarTopLabelImage {
    height: 1.5vw;
    width: 1.5vw;
    align-self: center;
    transform: inherit;
    margin-right: 0;
    margin-left: 0;
    transform: scale(0.9);
}

.loggedOutTopbarTopLabelTitle {
    align-self: center;
    user-select: none;
    color: rgba(255, 255, 255);
    font-weight: 750;
    letter-spacing: 0.3px;
    font-size: 1vw;
    transition: background-color 0.125s ease-in-out;
}

.overlayText {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2vw;
    color: white;
    font-weight: bold;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
    font-weight: 800;
}

.search-bar {
    width: 300px;
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    border-radius: 20px;
    background-color: #2a2a2a;
    color: #ffffff;
    transition: transform 0.2s;
}

.search-bar:focus {
    transform: scale(1.05);
    outline: none;
}

.smallLoggedOutTopbarButton:hover {
    transform: scale(1.09) rotate(0.25deg);
    background-color: rgb(255, 255, 255);
}

.smallLoggedOutTopbarButton:hover .loggedOutTopbarTopLabelTitle {
    color: rgb(25, 25, 25);
}

.smallLoggedOutTopbarButton:hover .loggedOutTopbarTopLabelImage {
    filter: invert(100%);
}

@media (max-width: 1200px) {
    .loggedOutTopbarTopLabelTitle {
        letter-spacing: 0.6px;
        font-size: 1.75vw;
    }

    .loggedOutTopbarTopLabelImage {
        height: 2.5vw;
        width: 2.5vw;
    }

    .smallLoggedOutTopbarButton {
        padding: 0.5vw 0.75vw;
        border-radius: 1vh;
    }

    .loggedOutTopbarTopSignUpHelp {
        font-size: 1.2vw;
    }
}

@media (max-width: 600px) {
    .loggedOutTopbarTopLabelTitle {
        letter-spacing: 0.75px;
        font-size: 5vw;
    }

    .loggedOutDashboardMain {
        max-width: 100%;
    }

    .loggedOutTopbarTopLabelImage {
        height: 5vw;
        width: 5vw;
    }

    .smallLoggedOutTopbarButton {
        padding: 1.15vw 1.5vw;
        border-radius: 1vh;
    }

    .loggedOutTopbarTopSignUpHelp {
        font-size: 2vw;
    }

    .loggedOutDashboardTopBar {
        max-width: 95%;
        display: flex;
        flex-wrap: wrap;
        gap: 1.1vw;
    }

    .loggedOutTopbarTopLabel {
        gap: 1.25vw;
    }

    .outer-def-container {
        flex-direction: column;
    }
}

@media (max-width: 400px) {
    .loggedOutTopbarTopLabelTitle {
        letter-spacing: 0.75px;
        font-size: 5.5vw;
    }

    .loggedOutDashboardMain {
        max-width: 100%;
    }

    .loggedOutTopbarTopLabelImage {
        height: 7.5vw;
        width: 7.5vw;
    }

    .smallLoggedOutTopbarButton {
        padding: 1.15vw 1.5vw;
        border-radius: 1vh;
    }

    .loggedOutTopbarTopSignUpHelp {
        font-size: 3vw;
    }

    .loggedOutDashboardTopBar {
        max-width: 95%;
        display: flex;
        flex-wrap: wrap;
        gap: 1.25vw;
    }
}

.fullScreenImageDiv {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}

.backgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70%;
    object-fit: cover;
    z-index: -1;
    transform: rotate(-0.005turn) scale(0.98);
}

.aweaweaew-def {
    display: flex;
    justify-content: center;
}


.outer-def-container {
    display: flex;
    justify-content: space-between;
    width: 75vw;
    flex-direction: row;
}

.subverbSpace {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 90px;
    margin-bottom: 90px;
    margin-left: 6vw;
}


.inner-def-left,
.inner-def-right {
    padding: 0 10px;
}

.gauntletDiv {
    margin-top: 23px;
    border-radius: 10px;
    cursor: pointer;
    padding: 6.5px 11px;
    max-width: 65%;
    background-color: var(--seventeenBackGroundColor);
    border: 3px solid rgba(45, 45, 45, 1);
    position: relative;
    display: inline-block;
    transition: background-color 0.125s ease-in-out, color 0.125s ease-in-out, transform 0.125s ease-in-out, border 0.125s ease-in-out;
}

.collegeSpaceSection101 {
    margin-bottom: 50px;
}

.akrNewAboutMeContainer {
    margin-top: 1vw;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);
    justify-content: center;
    border-radius: 15px;
    align-items: center;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vw;
}

.contentWrapper3Words {
    width: 29%;
    border-radius: 10px;
    overflow: hidden;
    margin: 1vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.threeWordsVisible {
    animation: fadeIn 0.5s forwards;
}

.graphicSection3Words {
    position: relative;
}

.imageBackground3Words {
    background-color: #000;
    border-radius: 15px;
    width: 100%;
    height: auto;
    overflow: hidden;
}

.imageBackground3Words {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.mainImage3Words {
    width: 100%;
    opacity: 0.5;
    object-fit: cover;
    object-position: center;
    display: block;
}

.overlatText3Words {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    padding: 5px 10px;
    font-size: 4vw;
    letter-spacing: 0.02vw;
    font-weight: 900;
    text-align: center;
    z-index: 1;
}

.infoSection3Words {
    padding: 20px;
}

.subverbBottom {
    font-size: 0.9vw;
    line-height: 1.6;
    color: black;
    font-weight: 800;
}

.goKartVideoDescription {
    width: 74%;
    margin-top: 30px;
    font-size: 16px;
    color: #ffffffc1;
    text-align: center;
    line-height: 1.5;
}

.imageCaptionCollege {
    width: 70%;
    margin-top: 30px;
    font-size: 18px;
    color: #000000d4;
}

.imageCaptionCollegeMaxHeight {
    width: 70%;
    margin-top: 30px;
    font-size: 18px;
    color: #000000d4;
    max-height: 385px;
    overflow-y: auto;
    padding: 0 20px;
}




@media (max-width: 800px) {
    .outer-def-container {
        flex-direction: column;
    }

    .navbar1Logo {
        margin-top: 15px;
        margin-left: 30px;
        width: 80px;
    }

    .inner-def-right {
        padding: 0;
    }

    .akrNewAboutMeContainer {
        transform: scale(0.9);
    }


    .akrNewAboutMeContainer {
        margin-top: 1vw;
        display: flex;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 1);
        justify-content: center;
        border-radius: 15px;
        align-items: center;
        overflow-x: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1vw;
    }

    .graphicSection3Words {
        position: relative;
    }

    .imageBackground3Words {
        background-color: #000;
        border-radius: 10px;
        width: 100%;
        height: auto;
        overflow: hidden;
    }

    .mainImage3Words {
        width: 100%;
        height: auto;
        opacity: 0.5;
    }

    .overlatText3Words {
        font-size: 10vw;
    }

    .infoSection3Words {
        padding: 20px;
    }

    .subverbBottom {
        font-size: 4vw;
        line-height: 1.6;
        color: black;
        font-weight: 800;
    }

    .contentWrapper3Words {
        max-width: 475px;
        border-radius: 10px;
        overflow: hidden;
        width: inherit;
        height: inherit;
    }

    .video-element,
    .imageCaptionCollege,
    .goKartVideoDescription {
        font-size: 16px;
        width: 90%;
    }

    .goKartVideoDescription {
        font-size: 12px;
        width: 80%;
    }
}