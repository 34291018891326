.NewUserNavBar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 7.5px;
  color: white;
}

.NewUserSystem {
  max-width: 90%;
}

.NewUserNavText {
  color: #000000;
  font-size: 25px;
  font-weight: 700;
}

@media (max-width: 600px) {
  .NewUserNavText {
    font-size: 15px;
  }
}