.article {
    margin-left: 12.5%;
    margin-top: 5%;
}

.articleTitle {
    font-size: 70px;
    justify-content: center;
    text-align: left;
    font-weight: 850;
    letter-spacing: 0.01vw;
    margin-bottom: 0.25vw
}

.markdown-image {
    max-width: 95%;
}

.article-mini-information {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 92.5%;
    gap: 0.75vw;
    margin-bottom: 0.3vw;
}

.small-information-piece {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.6vw;
}

.bigger-info-icon {
    height: 1.1vw;
    opacity: 0.8;
}

.bigger-info-text {
    font-size: 0.85vw;
    letter-spacing: 0.025vw;
    font-weight: 700;
    color: var(--usernameColor2);
    justify-self: center;
}

.darkerGrayBackgroundArticle {
    background-color: rgba(234, 234, 234, 0.05);
    padding: 0.21vw 0.6vw !important;
}

.mini-info-margin-bottom {
    margin-bottom: 0.75vw;
}

.articleText pre {
    overflow-x: auto;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

.articleText {
    margin-top: 2vw;
    font-size: 20px;
    font-weight: 700;
    opacity: 0.9;
    max-width: 90%;
    margin-bottom: 6.5vw;
}

.gameDetailsCreatorName {
    margin-right: 0vw !important;
    margin-left: 0.4vw !important;
    font-size: 0.75vw !important;
}

.gameDetailsMoreSpaceCheckMark {
    margin-left: 0.3vw !important;
    margin-right: 0vw;
    width: 0.85vw;
    height: 0.85vw;
}

@media (max-width: 850px) {
    .article {
        margin-left: inherit;
        margin-top: 10%;
    }

    .articleTitle {
        font-size: 8vw;
        justify-content: center;
        text-align: center;
        font-weight: 850;
        letter-spacing: 0.01vw;
        margin-bottom: 0.25vw
    }

    .articleText {
        text-align: center;
        font-size: 4vw;
        margin-left: auto;
        margin-right: auto;
    }

    .darkerGrayBackgroundArticle {
        margin-top: 5% !important;
        margin-bottom: 2%;
    }

    .gameDetailsAuthor {
        margin-top: 0.525vw !important;
        margin-right: 1.75vw !important;
        border-radius: 1.575vw !important;
        padding: 0.6125vw 2.25vw !important;
        transition: background-color 0.125s ease-in-out, transform 0.125s ease-in-out;
        backdrop-filter: blur(5px);
    }

    .gameDetailsCreatorName {
        margin-left: 1.75vw !important;
        font-size: 2.75vw !important;
    }

    .gameDetailsMoreSpaceCheckMark {
        margin-left: 1vw !important;
        width: 3.25vw !important;
        height: 3.25vw !important;
    }

    .gameDetailsCreatorIcon {
        width: 7vw !important;
        height: 7vw !important;
    }

    .small-information-piece {
        justify-self: center;
        gap: 2.5vw;
    }

    .article-mini-information {
        justify-content: center;
    }

    .bigger-info-icon {
        height: 5vw;
        opacity: 0.8;
    }

    .bigger-info-text {
        font-size: 4vw;
        letter-spacing: 0.025vw;
        font-weight: 700;
        color: var(--usernameColor2);
        justify-self: center;
    }
}