.robloxHomeCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.robloxHomeContainer {
    display: flex;
    justify-content: center;
    gap: 1vw;
}

.robloxHomeButton {
    height: 65vh;
    width: 25vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(20, 20, 20, 0.9);
    border: 3px solid rgba(255, 255, 255, 0.105);
    border-radius: 20px;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    text-decoration: none;
    transform: scale(1);
    transition: background-color 0.15s ease-in-out, transform 0.1s ease-in-out;
    animation: popIn 0.1s ease-out;
}

.robloxHomeButton:hover {
    transform: scale(1.025) rotate(0.25deg);
    background-color: rgb(255, 255, 255);
}

.robloxHomeButton:hover .robloxHomeLabelText {
    color: rgb(25, 25, 25);
}

.robloxHomeButton:hover .robloxHomeLabelSubText {
    color: rgb(25, 25, 25, 0.7);
}

.robloxHomeButton:hover .robloxHomeLabelIcon {
    filter: invert(100%);
}

.robloxHomeLabelDiv {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.robloxHomeLabelText {
    text-align: center;
    font-weight: 900;
    font-size: 1.75vw;
    letter-spacing: 0.5px;
}

.robloxHomeLabelSubText {
    max-width: 80%;
    text-align: center;
    font-weight: 700;
    color: var(--usernameColor1);
    font-size: 1vw;
    letter-spacing: 0.5px;
}

.robloxHomeLabelIcon {
    width: 7.5vw;
    height: 7.5vw;
}

@media (max-width: 800px) {
    .robloxHomeContainer {
        width: 100vw;
        align-items: center;
    }

    .robloxHomeButton {
        height: 27.5vh;
        width: 90%;
    }

    .robloxHomeLabelDiv {
        gap: 10px;
    }

    .robloxHomeContainer {
        flex-direction: column;
        gap: 2.5vw;
    }

    .robloxHomeLabelIcon {
        width: 25vw;
        height: 25vw;
    }

    .robloxHomeLabelText {
        font-size: 7.5vw;
    }

    .robloxHomeLabelSubText {
        font-size: 4vw;
    }
}