.notification-container {
  position: fixed;
  top: 70px;
  right: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 460px;
  z-index: 999999999;
}

.notification.show {
  animation: explodeIn 0.075s forwards;
  border: 3.5px solid rgba(255, 255, 255, 0.04);
}

.notification.hide {
  animation: explodeOut 0.075s forwards;
}

.notification-image {
  height: 60px;
  padding: 5px;
  max-height: 100px;
  margin-left: 17.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  user-select: none;
}

.notification-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  user-select: none;
}

.notification-content {
  padding: 15px 15px 5px 17.5px;
  max-width: 340px;
}

.notification-title {
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 4px;
}

.notification-message {
  font-size: 14.5px;
  font-weight: 500;
  margin: 0;
  margin-bottom: 9px;
}

.show {
  transform: scale(1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  backdrop-filter: blur(2px);
  margin-bottom: 8.5px;
  opacity: 0;
  transition: opacity 0.05s ease-in-out, background-color 0.025s ease-in-out, transform 0.25s ease-in-out;
}

.notification {
  display: flex;
  align-items: center;
  background-color: rgba(30, 30, 30, 0.75);
  border-radius: 17.5px;
  backdrop-filter: blur(2px);
  margin-bottom: 8.5px;
  font-family: 'Montserrat', sans-serif;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.05s ease-in-out, background-color 0.025s ease-in-out, transform 0.25s ease-in-out;
}

.notification:hover {
  background-color: rgba(70, 70, 70, 0.75);
}

.notification-button-container {
  display: flex;
  justify-content: flex-end;
  font-size: 12.5px;
  margin-bottom: -8px;
  color: var(--usernameColor2);
  transition: opacity 0.15s, margin-top 0.15s, margin-bottom 0.15s ease-in-out;
  opacity: 0;
}

.notification:hover .notification-button-container {
  opacity: 1;
  margin-top: 8px;
  margin-bottom: 3px;
}

.bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 3.5px;
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 0 0 15px 15px;
}

@media (max-width: 600px) {
  .notification-content {
    max-width: 315px;
  }

  .notification {
    max-width: 95% !important;
    margin-bottom: 0;
  }

  .notification-container {
    position: fixed;
    top: 70px;
    left: auto;
    right: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100% !important;
    align-items: center;
    justify-content: center;
    justify-items: center;
    backdrop-filter: initial;
    gap: 10px;
  }

  .notification-content {
    max-width: 700px !important;
  }

  .notification-image {
    max-height: 300px !important;
  }

  .notification-message {
    font-size: 16px !important;
    font-weight: 600 !important;
  }

  .notification-title {
    font-size: 18px !important;
    font-weight: 800 !important;
    letter-spacing: 0.1px;
  }
}

@keyframes explodeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px) scale(0.75);
  }

  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes explodeOut {
  0% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }

  100% {
    opacity: 0;
    transform: translateY(-10px) scale(0.5);
  }
}

@keyframes progressBar {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}