.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(navBarColor);
  padding: 0.35rem;
  color: white;
}

.notificationDropdownWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.navbarLoginButton {
  margin-right: 10px;
  position: relative;
  display: inline-flex;
  border-radius: 7.5px;
  align-items: center;
  cursor: pointer;
  padding: 10px 20px;
  background-color: rgba(250, 250, 250, 0.06);
  transition: background-color 0.15s ease-in-out;
  text-decoration: none;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  transition: background-color 0.15s ease-in-out, transform 0.1s ease-in-out;
  letter-spacing: 0.5px;
}

.navbarLogoLink {
  display: inline-block;
  transition: transform 0.1s ease-in-out;
  filter: var(--invertDarkModeColor) !important;
}

.navbarLogoLink:hover {
  transform: scale(1.05);
}

.centeredContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.notificationBellCSS {
  width: 25px;
  height: 25px;
  position: relative;
  display: inline-flex;
  border-radius: 7.5px;
  margin-right: 9px;
  padding: 7.5px;
  border: 3px solid rgba(255, 255, 255, 0.04);
  filter: var(--invertDarkModeColor) !important;
  transition: background-color 0.1s ease-in-out, transform 0.1s ease-in-out;
}

.notificationBellCSS:hover {
  background-color: rgba(210, 210, 210, 0.15);
  transform: scale(1.08);
}

.navbarLoginButton:hover {
  background-color: rgba(250, 250, 250, 0.25);
  transform: scale(1.05);
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbarLogo {
  width: 75px;
  height: 40px;
  margin-left: 20px;
  object-fit: contain;
  align-items: cover;
  pointer-events: none;
}

.navbar1Logo {
  width: 125px;
  margin-left: 30px;
  object-fit: contain;
  align-items: cover;
  pointer-events: none;
}

.notificationBellContainer {
  position: relative;
  margin-bottom: -6px;
}

.notificationCount {
  position: absolute;
  top: -1.9px;
  right: -1.9px;
  background-color: red;
  color: white;
  border-radius: 30%;
  width: 21px;
  height: 21px;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  transition: background-color 0.1s ease-in-out, transform 0.1s ease-in-out;
  pointer-events: none;
  user-select: none;
}

.profile-dropdown {
  position: relative;
  display: flex;
  align-items: center;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.profile-name {
  margin-right: 10.5rem;
}

.dropdown-content a,
.dropdown-content button {
  color: rgba(255, 255, 255, 1);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  border-radius: 5px;
  letter-spacing: 0.01vw;
}

.dropdown-content a:hover,
.dropdown-content button:hover {
  background-color: rgba(250, 250, 250, 0.068);
}

.profile-dropdown:hover .dropdown-content {
  display: block;
}

@media (max-width: 767px) {
  .notificationBellCSS {
    margin-right: 0px;
  }
}