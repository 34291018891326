.math-solver {
    margin: 20px;
    text-align: center;
}

.math-solver input {
    width: 300px;
    padding: 10px;
    margin: 10px;
    font-size: 16px;
}

.math-solver button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}