.quiz-container {
  display: flex;
  margin: 20px;
  padding: 20px;
}

.sidebar {
  flex: 1;
  padding: 20px;
}

.main-content {
  flex: 3;
  padding: 20px;
}

.question-nav {
  list-style: none;
  padding: 0;
}

.question-nav-item {
  cursor: pointer;
  padding: 10px 9px;
  margin-bottom: 7px;
  background-color: rgba(70, 70, 70, 0.6);
  border: 2px solid rgba(255, 255, 255, 0.105);
  text-align: center;
  border-radius: 10px;
  font-weight: 800;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease, border 0.3s ease;
}

.question-nav-item:hover {
  background-color: rgba(125, 125, 125, 0.6);
  transform: scale(1.02);
}

.answeredQuestion {
  background-color: rgba(23, 23, 23, 0.6);
  border: 2px solid rgba(255, 255, 255, 0.04);
  color: rgba(255, 255, 255, 0.8);
}

.question {
  font-weight: 800;
  letter-spacing: 0.01vw;
  font-size: 1.35vw;
  margin-bottom: 0.8vw;
}

.options {
  list-style: none;
  padding: 0;
}

.option {
  background-color: rgba(23, 23, 23, 0.6);
  border-radius: 0.4vw;
  margin: 10px 0;
  padding: 10px;
  cursor: pointer;
  position: relative;
  list-style-type: none;
  margin-bottom: 10px;
  padding-left: 25px;
  display: flex;
  align-items: center;
  max-width: 50%;
  gap: 15px;
  border: 2.25px solid rgba(255, 255, 255, 0.105);
  transition: background-color 0.25s ease, color 0.25s ease, transform 0.25s ease;
}

.unselected {
  background-color: rgba(18, 18, 18, 0.336);
  border: 2px solid rgba(255, 255, 255, 0.105);
  color: rgba(255, 255, 255, 0.65);
  transform: scale(0.985) !important;
}

.unselected:hover {
  transform: scale(0.985) !important;
}

.option:hover {
  background-color: rgba(80, 80, 80, 0.6);
  transform: scale(1.02);
}

.option-letter {
  width: 5px;
  height: 5px;
  padding: 13px;
  background-color: rgba(100, 100, 100, 0.6);
  color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2.5px solid rgba(255, 255, 255, 0.105);
  box-sizing: border-box;
  text-align: center;
}


.score {
  font-size: 18px;
  margin-top: 20px;
}

.questionsAnswered {
  font-size: 0.85vw;
  margin-bottom: 2vw;
  font-weight: 800;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}

.searchVersion {
  font-size: 0.85vw;
  margin-bottom: 2vw;
  font-weight: 800;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}

.sidebarTitle {
  font-size: 1.5vw;
  margin-bottom: 0.25vw;
  font-weight: 900;
  text-align: center;
  letter-spacing: 0.025vw;
  opacity: 0.95;
}

.text-piece {
  color: rgba(255, 255, 255, 0.85);
  font-size: 1.1vw;
  margin-bottom: 1.1vw;
}

.navigation-buttons {
  display: flex;
  margin-top: 20px;
  text-align: right;
  gap: 0.4vw;
}

.quizButton {
  padding: 9px 16px;
  border: none;
  border-radius: 10px;
  background-color: rgba(23, 23, 23, 0.6);
  color: white;
  cursor: pointer;
  font-weight: 600;
  border: 2px solid rgba(255, 255, 255, 0.105);
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;
}

.quizButton:hover {
  background-color: rgba(60, 60, 60, 0.6);
  transform: scale(1.02);
}

.quizButton.lighter {
  background-color: rgba(60, 60, 60, 0.6);
}

.quizButton.disabled {
  background-color: rgba(18, 18, 18, 0.5);
  border: 2px solid rgba(255, 255, 255, 0.105);
  color: rgba(255, 255, 255, 0.65);
  transform: scale(0.95);
  cursor: not-allowed;
}

.mathEquationBg {
  font-family: 'Montserrat', sans-serif;
  border-radius: 10px;
  border: 3px solid rgba(255, 255, 255, 0.105);
  background-color: rgb(20, 20, 20, 0.9);
  color: white;
  font-weight: 700;
  padding: 10px;
}

@media (max-width: 650px) {
  .quiz-container {
    flex-direction: column;
  }

  .sidebarTitle {
    font-size: 7.5vw;
  }

  .sidebarSearchTitle {
    font-size: 7.5vw;
  }

  .quiz-container {
    margin: inherit;
    padding: inherit;
  }

  .question-nav {
    display: flex;
    flex-direction: column;
    gap: 3.5px;
  }

  .questionsAnswered {
    font-size: 5vw;
  }

  .searchSidebar {
    padding: 0;
    font-size: 0;
    display: none;
  }

  .searchVersion {
    font-size: 3vw;
  }

  .text-piece {
    color: rgba(255, 255, 255, 0.85);
    font-size: 15px;
    margin-bottom: 1.1vw;
  }

  .question {
    margin-top: 25px;
    font-size: 5vw;
  }

  .option {
    max-width: 100%;
    border-radius: 7.5px;
    padding-left: 15px !important;
  }

  .main-content {
    padding: 0 20px;
  }

  .navigation-buttons {
    margin-bottom: 25px;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
}