.chatDashboard {
    padding: 1rem;
    max-height: 90%;
}

.chatPeople {
    width: 15vw;
    height: 89vh;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-right: 2vw;
    overflow-y: auto;
    overflow-x: hidden;
}

.chatButtonPictureContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5vw;
}

.chatButtonPictures {
    margin-left: 0.65vw;
    width: 3.5vw;
    height: 3.5vw;
    margin-right: 10px;
    border-radius: 20%;
    object-fit: cover;
    pointer-events: none;
    transform: scale(1.01)
}

.centerObjects {
    align-items: center !important;
    justify-content: center !important;
}

.discriptionModalConvoInfo {
    text-align: center;
    margin-top: 0.1vw;
    font-size: 0.9vw;
    font-weight: 800;
    margin-bottom: 0.5vw;
}

.topMessageButton {
    margin-left: 0.1vw;
    padding: 0.35vw;
    background-color: rgba(96, 96, 96, 0);
    border-radius: 0.55vw;
    display: flex;
    flex-direction: row;
    transition: background-color 0.125s ease-in-out, transform 0.1s ease-in-out;
}

.topModalDisplay {
    justify-content: center;
    align-items: center;
    margin-left: 0.1vw;
    padding: 0.35vw;
    background-color: rgba(96, 96, 96, 0);
    border-radius: 0.55vw;
    display: flex;
    flex-direction: row;
    transition: background-color 0.125s ease-in-out, transform 0.1s ease-in-out;
}

.topInfoDisplay {
    z-index: auto;
    backdrop-filter: blur(5px);
    padding: 0.25vw;
    border-radius: 0.85vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 0.25vw
}

.xButton {
    width: 1.75vw;
    height: 1.75vw;
    border-radius: 50%;
    justify-content: center;
}

.topMessageContainerPicture {
    width: 2.4vw;
    height: 2.4vw;
    pointer-events: none;
    border-radius: 50%;
}

.topModalContainerPicture {
    width: 3.5vw;
    height: 3.5vw;
    border-radius: 50%;
}

.topMessageButton:hover {
    transform: scale(1.02);
    background-color: rgba(96, 96, 96, 0.25);
}

.topMessageContainerProfilePicture {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}

.chatButtonInfo {
    background-color: rgba(96, 96, 96, 0.1);
    border-radius: 20px;
    border: 3px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: row;
    margin-bottom: -13.5px;
    transform: scale(0.965);
    transition: background-color 0.125s ease-in-out, transform 0.1s ease-in-out;
}

.chatMessageTextInfo {
    user-select: none;
    margin-top: 0.2vw;
    margin-left: 0.5vw;
    font-weight: 900;
    font-size: 0.8vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.convoInfoModalText {
    font-size: 1.1vw;
}

.chatButtonTextInfo {
    user-select: none;
    margin-top: 0.75vw;
    font-weight: 900;
    font-size: 0.8vw;
    padding: 0 0.75vw 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.whiteChatButton {
    transform: scale(0.99) rotate(0.25deg);
    background-color: rgb(255, 255, 255);
}

.whiteChatButton .chatButtonTextInfo {
    color: rgb(25, 25, 25) !important;
}

.whiteChatButton .chatButtonUserName {
    color: rgb(25, 25, 25, 0.7) !important;
}

.chatButtonInfo:hover {
    transform: scale(0.99) rotate(0.25deg);
    background-color: rgb(255, 255, 255);
}

.chatButtonInfo:hover .chatButtonTextInfo {
    color: rgb(25, 25, 25);
}

.chatButtonInfo:hover .chatButtonUserName {
    color: rgb(25, 25, 25, 0.7);
}

.chatButtonUserName {
    margin-top: 0.1vw;
    color: var(--usernameColor2);
    font-size: 0.54vw;
    font-weight: 800;
}

.convoInfoModalUserName {
    font-size: 0.6vw;
}

.chatDashboard {
    display: flex;
    padding: 1rem;
    max-height: 90%;
}

.chatMessage {
    max-height: 90%;
    border-radius: 0.85vw;
    position: relative;
    width: 82.5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.chatMessageColor {
    background-color: rgba(96, 96, 96, 0.1);
}

.centerChatBubble {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.centeredText {
    font-weight: 800;
    font-size: 2.2vw;
    margin-bottom: 0.5vw;
}

.leftTextAllign {
    text-align: left !important;
}

.bottomTextBar {
    user-select: none;
    background-color: rgba(185, 185, 185, 0.15);
    color: white;
    border: 3px solid rgba(255, 255, 255, 0.04);
    border-radius: 7.5px;
    padding: 0.32vw 0.6vw;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    font-weight: 600;
    letter-spacing: 0.2px;
    text-decoration: none;
    font-size: 0.85vw;
    transition: background-color 0.125s ease-in-out, transform 0.1s ease-in-out;
    margin-bottom: 1%;
    margin-left: 1%;
    width: 90%;
}

.messageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.bottomSendButton {
    user-select: none;
    background-color: rgba(185, 185, 185, 0.15);
    color: white;
    border: 3px solid rgba(255, 255, 255, 0.04);
    border-radius: 7.5px;
    padding: 0.32vw 0.6vw;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    font-weight: 700;
    letter-spacing: 0.2px;
    text-decoration: none;
    font-size: 0.85vw;
    transition: background-color 0.125s ease-in-out, transform 0.1s ease-in-out;
    margin-bottom: 1%;
    margin-left: 1%;
    width: 3%;
}

.messagesContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    max-height: 80vh;
    margin-bottom: 1%;
}

.message {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 10px;
    max-width: 60%;
    transform: scale(1);
    transition: background-color 0.1s ease-in-out, transform 0.1s ease-in-out;
}

.message:hover {
    background-color: rgba(96, 96, 96, 0.2);
}

.messageHeader {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 0.55vw;
}

.messageBody {
    margin-top: 7.5px;
    background-color: rgba(96, 96, 96, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0.3vw;
    font-weight: 700;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.35vw 0.45vw;
}

.messageSent {
    align-self: flex-end;
    margin-right: 1.2%;
}

.messageReceived {
    align-self: flex-start;
    margin-left: 1.2%;
}

.messagePicturecontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.messagePicture {
    width: 2.2vw;
    height: 2.2vw;
    border-radius: 50%;
    object-fit: cover;
    pointer-events: none;
    transform: scale(1.025);
    margin-right: 0.1vw;
}

.messageTextInfo {
    font-weight: 800;
    font-size: 0.82vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.messageUserName {
    font-weight: 700;
    font-size: 0.5vw;
    color: rgba(255, 255, 255, 0.7);
}

.messageName {
    white-space: nowrap;
}

.messageSent .messageTextInfo {
    align-items: flex-end;
}

.messageReceived .messageHeader {
    flex-direction: row;
}

.messageSent .messageHeader {
    flex-direction: row-reverse;
}

.messageSent .messageBody {
    text-align: right;
}

.messageSent .topMessageInfo {
    flex-direction: row-reverse;
}

.topMessageInfo {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 0.5vw;
}

.topLinenInfo {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 0.15vw;
    margin-top: 0vw;
}

.smallRightMargin {
    margin-right: 0.3vw;
}

.chatDashboardOr {
    font-size: 1vw;
    margin-top: 0.15vw;
    margin-bottom: 0.325vw;
    font-weight: 700;
}

.chatNewConvoButton {
    font-weight: 800;
    letter-spacing: 0.005vw;
    background-color: rgb(20, 20, 20, 0.9);
    border: 1.5px solid rgba(255, 255, 255, 0.1);
    padding: 0.45vw 0.65vw;
    transition: background-color 0.1s ease-in-out, transform 0.1s ease-in-out;
    border-radius: 0.5vw;
    font-size: 1vw;
    margin-bottom: 0.5vw;
}

.chatNewConvoButton:hover {
    background-color: rgba(60, 60, 60, 0.9);
    transition: background-color 0.15s ease-in-out, transform 0.1s ease-in-out;
    transform: scale(1.06);
}

.messageDate {
    font-weight: 600;
    font-size: 0.6vw;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 0.04vw
}

.chatDashboardVerified {
    user-select: none;
    object-fit: cover;
    position: relative;
    display: inline-flex;
    border-radius: 7.5px;
    padding: 0.15vw 0.15vw;
    transition: background-color 0.15s ease-in-out;
    transition: transform 0.1s ease-in-out;
    width: 1.1vw;
    height: 1.1vw;
}

.chatDashboardVerified:hover {
    background-color: rgba(250, 250, 250, 0.175);
    transform: scale(1.025);
}

.convoSpace {
    margin-bottom: 1vw;
}

.smallerConvoSpace {
    margin-bottom: 0.3vw;
    transform: scale(0.96);
}

.messageModal {
    width: 40vw;
}

.newConvoModal {
    height: 50vh;
    width: calc(2.5 * 20vh);
}

.selectedNewConvo {
    background-color: rgba(95, 146, 255, 0.248) !important;
}

.selectedNewConvo:hover {
    background-color: rgba(95, 146, 255, 0.248) !important;
    transform: scale(1);
}

.selectedNewConvo:hover .chatButtonTextInfo {
    color: rgb(255, 255, 255) !important;
}

.selectedNewConvo:hover .chatButtonUserName {
    color: rgba(255, 255, 255, 0.7) !important;
}

.usersScrollChatModal {
    scroll-behavior: smooth;
    width: 100%;
    height: 30vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.fullWidth {
    width: 100vw !important;
}

.destroyMessageHover {
    transform: scale(1.5);
    margin-top: 1vw;
}

.destroyMessageHover:hover {
    background-color: rgba(96, 96, 96, 0.2) !important;
    transform: scale(1.5) !important;
}

@media (max-width: 1425px) {
    .chatPeople {
        width: 20vw;
        height: 100%;
        overflow-y: visible;
    }

    .chatButtonPictures {
        width: 5vw;
        height: 5vw;
    }

    .chatButtonTextInfo {
        font-size: 1.1vw;
    }

    .chatButtonUserName {
        margin-top: 0.1vw;
        font-size: 0.7vw;
        letter-spacing: 0.03vw;
    }

    .messageTextInfo {
        font-size: 1vw;
    }

    .messageUserName {
        font-size: 0.7vw;
    }

    .messageDate {
        font-size: 0.7vw;
    }

    .messagePicture {
        width: 2.9vw;
        height: 2.9vw;
    }

    .messageBody {
        font-size: 1.1vw;
    }
}

@media (max-width: 600px) {
    .chatDashboard {
        flex-direction: column-reverse;
        gap: 3vw;
    }

    .chatPeople {
        width: 95vw;
        align-self: center;
        height: initial;
        overflow-y: visible;
        margin-bottom: 2.5vw;
        margin-right: initial !important;
        gap: 15px;
        overflow-x: initial !important;
    }

    .newConvoModal {
        width: 85vw;
    }

    .chatMessage {
        align-self: center;
        width: 95vw;
        height: 150vw;
    }

    .inactiviySmoller {
        margin-top: 5vw;
        font-size: 2vw;
        max-width: 90%;
    }

    .chatButtonInfo {
        border-radius: 4vw !important;
        transform: scale(0.96) !important;
        margin-bottom: -10px !important
    }

    .chatButtonInfo:hover {
        transform: scale(0.98) !important;
        background-color: rgba(96, 96, 96, 0.1) !important;
    }

    .chatButtonInfo:hover .chatButtonTextInfo {
        color: rgba(255, 255, 255, 1);
    }

    .chatButtonInfo:hover .chatButtonUserName {
        color: var(--usernameColor2);
    }

    .whiteChatButton:hover .chatButtonTextInfo {
        color: rgba(255, 255, 255, 1) !important;
    }

    .whiteChatButton:hover .chatButtonUserName {
        color: var(--usernameColor2) !important;
    }

    .centeredText {
        font-size: 10vw;
    }

    .chatDashboardOr {
        font-size: 5vw;
    }

    .messageName {
        font-size: 3vw;
    }

    .messageDate {
        font-size: 2.1vw;
    }

    .messageBody {
        font-size: 3.5vw;
        padding: 1vw 1.2vw;
        border-radius: 1.75vw;
    }

    .messageUserName {
        font-size: 2.1vw;
    }

    .xButton {
        width: 15vw;
        height: 15vw;
    }

    .topLinenInfo {
        margin-top: 1vw;
        font-size: 5vw;
        gap: 0.45vw;
    }

    .topMessageContainerPicture {
        width: 12vw;
        height: 12vw;
    }

    .topMessageButton {
        padding: 1.5vw;
    }

    .smallRightMargin {
        margin-left: 2.5vw;
    }

    .topInfoDisplay {
        width: 95vw;
        left: auto;
        align-self: center;
        justify-content: center;
    }

    .topMessageInfo {
        gap: 1.5vw
    }

    .bottomTextBar {
        padding: 1vw 2vw;
        font-size: 4vw;
        width: 73%;
    }

    .bottomSendButton {
        padding: 1vw 2vw;
        font-size: 4vw;
        width: 15%;
    }

    .messagePicture {
        height: 10vw;
        width: 10vw;
    }

    .chatNewConvoButton {
        margin-top: 2vw;
        font-size: 6vw;
        padding: 2.5vw 2.9vw;
        border-radius: 2.5vw;
    }

    .chatButtonUserName {
        font-size: 3vw;
    }

    .message {
        max-width: inherit !important;
    }

    .messageHeader {
        gap: 2vw;
    }

    .chatButtonPictureContainer {
        justify-content: center;
    }

    .chatButtonTextInfo {
        font-size: 4.2vw;
        margin-top: 0vw;
        justify-content: center;
        padding: 3vw 0vw;
    }

    .chatButtonPictureContainer {
        padding: 2vw;
    }

    .chatButtonPictures {
        height: 15vw;
        width: 15vw;
    }
}