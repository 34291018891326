.searchOld {
  max-width: 400px;
  margin: 4rem auto;
  padding: 2rem;
  text-align: center;
  border-radius: 8px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
}

.searchOldInput {
  font-family: 'Montserrat', sans-serif;
  width: 85%;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255);
  font-size: 20px;
  font-weight: 700;
  border: 3px solid rgba(255, 255, 255, 0.1);
  height: 30px;
  border-radius: 12px;
  padding: 10px 10px 10px 55px;
  margin-top: 10px;
  transition: background-color 0.3s;
  background-image: url('./Icons/searchIcon.png');
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 25px;
}

.searchOldInput:focus {
  background-color: rgba(255, 255, 255, 0.2);
  outline: none;
}

.mini-social-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(17, 17, 17, 0.95);
  border: 3.5px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 7px;
  width: calc(12.5% - 10px);
  min-height: 175px;
  transition: transform 0.1s ease-in-out;
}

.mini-social-card:hover {
  background-color: rgba(193, 193, 193, 0.25);
  transform: scale(1.05);
}

.miniUsernameBox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.mini-verifiedPicture {
  position: relative;
  display: inline-flex;
  border-radius: 7.5px;
  padding: 4px 6px;
  transition: background-color 0.15s ease-in-out;
  transition: transform 0.1s ease-in-out;
  margin-top: -3px;
  margin-right: -5px;
  width: 25px;
  height: 25px;
  user-select: none;
  object-fit: cover;
  margin-bottom: -10px;
  margin-left: 5px;
  margin-bottom: 0;
  margin-top: 0;
}

.mini-verifiedPicture:hover {
  background-color: rgba(250, 250, 250, 0.175);
  transform: scale(1.025);
}

.mini-profile-pic {
  margin-top: 10px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.mini-profile-info {
  display: flex;
  flex-direction: column;
}

.mini-profile-username {
  margin-top: 1px;
  text-align: center;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}

.verificationSection {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.mini-profile-status {
  margin-top: 5px;
  text-align: center;
  font-size: 12px;
  color: var(--usernameColor2);
  margin-bottom: 5px;
}

.searchOld-results {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.searchOldInput-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (min-width: 0px) {
  .mini-social-card {
    width: calc(75% - 10px);
  }

  .mini-verifiedPicture {
    margin-left: 2px;
  }

  .searchOldInput-container {
    justify-content: center;
  }

  .searchOldInput {
    justify-content: center;
  }
}

@media (min-width: 500px) {
  .mini-social-card {
    width: calc(45% - 10px);
  }
}

@media (min-width: 1000px) {
  .mini-social-card {
    width: calc(27.5% - 10px);
  }
}

@media (min-width: 1500px) {
  .mini-social-card {
    width: calc(20% - 10px);
  }
}

@media (min-width: 1750px) {
  .mini-social-card {
    width: calc(15% - 10px);
  }
}