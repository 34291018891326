.individual-container {
    margin-top: 5px;
    margin-left: 35px;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 10px;
    font-family: 'Montserrat', sans-serif;
}

.options-container {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    height: 100%;
    background-color: rgba(250, 250, 250, 0.05);
    width: 80%;
    border-radius: 16px;
    margin: 0 auto;
    padding-top: 10px;
    padding-bottom: 10px;
}

.social-button-container {
    text-align: center;
}

.settings-profile-picture {
    margin-left: 11.5vw;
    margin-top: 20px;
    width: 7vw;
    height: 7vw;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 1rem;
    pointer-events: none;
}

.friendUsernameBox {
    display: inline-block;
    margin-top: 30px;
    font-weight: 900;
    font-size: 2vw;
}

.label-container {
    display: flex;
    align-items: center;
    margin-left: 1.25rem;
    margin-top: 1rem;
    margin-bottom: 20px;
}

.settings-icon {
    margin-right: 30px;
    height: 100px;
    width: 100px;
    pointer-events: none;
}

.settingsLabel {
    color: rgba(255, 255, 255);
    font-size: 52.5px;
    margin-bottom: 13px;
    font-weight: 900;
    user-select: none;
}

.optionDescriptionText {
    color: rgba(255, 255, 255);
    font-size: 20px;
    font-weight: 700;
    margin-right: 25px;
}

.optionValuesText {
    color: rgba(255, 255, 255, 0.75);
    font-size: 17.5px;
    font-weight: 700;
    margin-left: 10px;
}

.edit-button {
    font-family: 'Montserrat', sans-serif;
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255);
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: 'Montserrat', sans-serif;
}

.edit-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.friendUserName {
    margin-top: -2px;
    color: var(--usernameColor2);
    font-size: 0.8vw;
}

.options-container {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    height: 100%;
    background-color: rgba(250, 250, 250, 0.05);
    width: 80%;
    border-radius: 16px;
    margin: 0 auto;
    padding-top: 10px;
    padding-bottom: 10px;
}

.label-container {
    display: flex;
    align-items: center;
    margin-left: 1.25rem;
    margin-top: 1rem;
    margin-bottom: 20px;
}

.settings-icon {
    margin-right: 20px;
    height: 95px;
    width: 95px;
}

.optionDescriptionText {
    color: rgba(255, 255, 255);
    font-size: 20px;
    font-weight: 700;
    margin-right: 25px;
}

.optionValuesText {
    color: rgba(255, 255, 255, 0.75);
    font-size: 17.5px;
    font-weight: 700;
    margin-left: 10px;
}

.edit-button {
    font-family: 'Montserrat', sans-serif;
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255);
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    margin-left: 10px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.3s;
}

.edit-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.editSocialsButton {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 12.5px;
    font-weight: 700;
    position: relative;
    border-radius: 7.5px;
    align-items: center;
    cursor: pointer;
    font-size: 0.9vw;
    padding: 0.35vw 0.5vw;
    background-color: rgb(20, 20, 20, 0.9);
    font-weight: 750;
    letter-spacing: 0.15px;
    transition: transform 0.1s ease-in-out;
    color: #ffffff;
    user-select: none;
}

.settingList {
    margin-top: 15px;
    margin-left: 13vw;
}

.editSocialsButton:hover {
    transform: scale(1.035);
}

.socialModail {
    height: 45vh;
    width: 45vw;
}

.statusModail {
    width: 700px;
}

.socialModailText {
    font-size: 32.5px;
    margin-top: 0.5vw;
    margin-left: 0.5vw;
    font-weight: 900;
}

.modalBigText {
    font-size: 32.5px;
    font-weight: 900;
}

.modalSubText {
    color: var(--usernameColor2);
    font-size: 17.5px;
    margin-bottom: 0.25vw;
}

.smallSocialModailText {
    margin-top: 0.1vw;
    margin-left: 0.5vw;
    color: var(--usernameColor2);
    font-size: 17.5px;
    margin-bottom: 0.25vw;
}

.lessSmallerSocialModailText {
    margin-top: 0.1vw;
    margin-left: 0.5vw;
    color: var(--usernameColor2);
    font-size: 17.5px;
    margin-bottom: 0.25vw;
}

.centeredSocialMediaDiv {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.statusesContainer {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 7px;
}

.widerSettingsSocialIcon {
    height: 80%;
    pointer-events: none;
    user-select: none;
    object-fit: cover;
}

.settingSocialIcon {
    height: 90%;
    pointer-events: none;
    user-select: none;
    object-fit: cover;
}

.settingsSocialStatus {
    place-items: center;
    user-select: none;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
    position: relative;
    display: inline-flex;
    border-radius: 7.5px;
    align-items: center;
    cursor: pointer;
    padding: 0.6vh 0.5vw;
    background-color: rgba(125, 125, 125, 0.25);
    transform: scale(1);
    transition: transform 0.1s ease-in-out;
    font-weight: 750;
    border: none;
    color: #ffffff;
    font-size: 0.85vw;
}

.settingsSocialIcon {
    height: 1vw;
    margin-right: 8px;
    pointer-events: none;
    user-select: none;
    object-fit: cover;
}

.biggerSocialButton {
    font-size: 1vw;
    padding: 0.4vw 0.6vw !important;
    font-weight: 700 !important;
}

.evenBiggerSocialButton {
    font-size: 1.1vw;
    padding: 0.4vw 0.6vw !important;
    font-weight: 800 !important;
}

.mehSocialButton {
    font-size: 0.9vw;
    font-weight: 700 !important;
    padding: 0.4vw 0.6vw !important;
    max-width: 15vw !important;
}

.meherSocialButton {
    font-size: 0.9vw;
    background-color: rgba(110, 110, 110, 0.125);
    font-weight: 700 !important;
    padding: 8px 11px !important;
    width: 95%;
}

.smallerSocialButton {
    max-width: 10vw !important;
}

.settingsSeperation {
    margin-top: 30px;
    margin-bottom: 9px;
}

.bottom-right-ui {
    user-select: none;
    background-color: rgba(185, 185, 185, 0.15);
    color: white;
    border: 1.5px solid rgba(255, 255, 255, 0.1);
    border-radius: 7.5px;
    padding: 0.32vw 0.6vw;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    font-weight: 600;
    letter-spacing: 0.2px;
    text-decoration: none;
    font-size: 0.65vw;
    transition: background-color 0.125s ease-in-out, transform 0.1s ease-in-out;
    margin-right: 10px;
}

.bottom-right-ui:hover {
    background-color: rgba(150, 150, 150, 0.4);
    transform: scale(1.05);
}

.bottom-right-ui-container {
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 1000;
    display: flex;
}

.modalBottomUi {
    position: initial;
    flex-direction: row-reverse;
    margin-top: 9px;
    gap: 7.5px;
}

.settingsSocialStatus:hover {
    transition: transform 0.1s ease-in-out;
    transform: scale(1.02);
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0);
    }

    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }

    100% {
        opacity: 0;
        transform: translate(-50%, -50%) scale();
    }
}

@keyframes modalFadeIn {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.9);
    }

    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

@keyframes modalFadeOut {
    0% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }

    100% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(1.1);
    }
}

.settingsStatusText {
    font-size: 1vw;
    margin-top: 0.15vw;
    margin-bottom: 0.325vw;
    font-weight: 700;
    user-select: none;
}

@media (max-width: 1450px) {
    .bottom-right-ui {
        font-size: 1vw;
        padding: 0.5vw 1vw;
    }
}

@media (max-width: 850px) {
    .bottom-right-ui {
        font-size: 1.3vw;
        padding: 0.7vw 1.3vw;
    }
}

@media (max-width: 610px) {
    .bottom-right-ui {
        font-size: 2.4vw;
        padding: 1.32vw 2.4vw;
    }

    .settings-icon {
        margin-right: 10px;
        height: 50px;
        width: 50px;
    }

    .settingsLabel {
        font-size: 30px;
        user-select: none;
    }

    .label-container {
        margin-left: 25px;
    }

    .settingsSocialStatus {
        font-size: 4vw !important;
    }

    .subjectCards {
        font-size: 4vw !important;
    }

    .settingsStatusText {
        font-size: 1.5vh;
    }

    .settings-profile-picture {
        width: 50vw;
        height: 50vw;
        margin-left: 0;
    }

    .friendUsernameBox {
        font-size: 7.5vw;
    }

    .friendUserName {
        font-size: 3.5vw;
    }

    .editSocialsButton {
        font-size: 3.5vw;
        padding: 1.25vw 2.5vw;
    }

    .friendUsernameBox {
        display: flex;
        align-items: center;
        flex-direction: column
    }

    .settingList {
        margin-left: 0;
    }

    .biggerSocialButton {
        max-width: inherit !important;
        font-size: 4vw;
        padding: 1vw 1.25vw !important;
    }

    .evenBiggerSocialButton {
        max-width: inherit !important;
        font-size: 7vw !important;
        padding: 1vw 1.25vw !important;
    }

    .statusesContainer {
        justify-content: center;
    }

    .settingsSocialStatus {
        max-width: inherit !important;
        font-size: 4vw;
        padding: 1vw 1.25vw !important;
    }

    .smallerSocialButton {
        max-width: inherit !important;
        font-size: 4vw;
        padding: 1vw 1.25vw !important;
    }

    .settingsSocialIcon {
        height: 7.5vw;
    }

    .smollerSocialButton {
        font-size: 6vw !important;
        padding: 1.75vw 3vw !important;
    }

    .smollerSocialButton {
        max-width: 45vw !important;
    }

    .settingsSeperation {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}