.user-details-container {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    margin-top: 8vh;
    margin-left: 12vw;
    white-space: pre-wrap;
    background-size: contain;
}

.user-details-container-center {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 3%;
    white-space: pre-wrap;
    max-width: 145vw;
    margin-left: 31.5%;
    gap: calc(1.25vw);
    margin-right: 1vw;
    background-size: contain;
    margin-bottom: 10px;
}

.user-profile-picture {
    user-select: none;
    margin-top: 2.9vh;
    width: 6vw;
    height: 6vw;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 1.5vh;
}

.dashboardHi {
    user-select: none;
    margin-top: 2.40vh;
    color: rgb(191, 179, 146);
    font-family: 'Montserrat', sans-serif;
    font-size: 8vh;
    margin-bottom: 0.38vh;
    font-weight: 800;
    filter: drop-shadow(0 0 0.4vw rgba(191, 179, 146, 0.2))
}

.dashboardProfileName {
    user-select: none;
    color: rgb(191, 179, 146);
    font-family: 'Montserrat', sans-serif;
    font-size: 9vh;
    font-weight: 850;
    letter-spacing: 0.5px;
    filter: drop-shadow(0 0 0.4vw rgba(191, 179, 146, 0.2))
}

.dashboardProfileNameNew {
    user-select: none;
    color: rgb(0, 0, 0);
    font-family: 'Montserrat', sans-serif;
    font-size: 9vh;
    font-weight: 850;
    letter-spacing: 0.5px;
    filter: drop-shadow(0 0 0.4vw rgba(191, 179, 146, 0.2))
}

.subverbNew {
    user-select: none;
    color: rgba(0, 0, 0, 0.7);
    font-family: 'Montserrat', sans-serif;
    font-size: 5.5vh;
    font-weight: 850;
    letter-spacing: 0.5px;
    filter: drop-shadow(0 0 0.4vw rgba(191, 179, 146, 0.2))
}

.dashboardProfileSubtext {
    user-select: none;
    color: rgb(79, 79, 79);
    font-family: 'Montserrat', sans-serif;
    font-size: 2vh;
    margin-bottom: 0.29vh;
    font-weight: 750;
    letter-spacing: 0.5px;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.schoolMotiveText {
    user-select: none;
    color: rgb(115, 115, 115);
    font-family: 'Montserrat', sans-serif;
    font-size: 3.5vh;
    margin-bottom: 0.29vh;
    font-weight: 700;
    letter-spacing: 0.5px;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.dashboardMotiveText {
    user-select: none;
    color: rgb(150, 150, 150);
    font-family: 'Montserrat', sans-serif;
    font-size: 5vh;
    margin-bottom: 0.29vh;
    font-weight: 700;
    letter-spacing: 0.5px;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.threeBoxDivCollege {
    display: flex;
    justify-content: center;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(45px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.threeBoxDivCollegeDiv {
    display: flex;
    justify-content: center;
    max-width: 85vw;
    gap: 10px;
    transform: translateY(45px);
    transition: opacity 0.6s, transform 0.6s;
    opacity: 0;
}

.threeWordsVisible {
    animation: fadeIn 0.6s forwards;
}

.smallerDashboardMotiveText {
    max-width: 70%;
    user-select: none;
    color: rgb(150, 150, 150);
    font-family: 'Montserrat', sans-serif;
    font-size: 3vh;
    margin-bottom: 0.29vh;
    font-weight: 700;
    letter-spacing: 0.5px;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

@keyframes popIn {
    0% {
        transform: scale(0.5);
    }

    100% {
        transform: scale(1);
    }
}

.centered-button {
    background-size: contain;
    width: 19vw;
    height: calc(19.5vw * 0.4375);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 3.75vh;
    background-color: rgb(20, 20, 20, 0.9);
    border: 3px solid rgba(255, 255, 255, 0.105);
    border-radius: 25px;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    text-decoration: none;
    min-height: 10%;
    filter: var(--invertDarkModeColor) !important;
    transition: background-color 0.15s ease-in-out, transform 0.1s ease-in-out;
    animation: popIn 0.1s ease-out;
}

.centered-button:hover {
    transform: scale(1.09) rotate(0.25deg);
    background-color: rgb(255, 255, 255);
}

.centered-button:hover .label1 {
    color: rgb(25, 25, 25);
}

.centered-button:hover .label2 {
    color: rgb(25, 25, 25, 0.7);
}

.centered-button:hover .button-image {
    filter: invert(100%);
    transform: scale(1.8);
}

.centered-button:hover .uninvert {
    filter: invert(0%) !important;
    transform: scale(1.8);
}

.button-content {
    margin-left: -0.15vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}

.button-text {
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    width: 107.5%;
}

.text-wrapper {
    margin-left: -0.15vw;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0.7vh;
}

.label1 {
    user-select: none;
    color: rgba(255, 255, 255);
    margin-bottom: 0.77vh;
    margin-left: 0.1vw;
    font-weight: 850;
    letter-spacing: 0.4px;
    text-align: left;
    font-size: 1.7vw;
    transition: background-color 0.125s ease-in-out;
}

.label2 {
    user-select: none;
    margin-top: -0.9vh;
    color: rgba(255, 255, 255, 0.65);
    font-weight: 700;
    font-size: 0.925vw;
    width: 112.2%;
    text-align: left;
    margin-left: 0.65vw;
    margin-bottom: -2vh;
    transition: background-color 0.125s ease-in-out;
}

.button-image {
    user-select: none;
    margin-right: calc(0.7 * 1vw);
    width: 3vw;
    height: 3vw;
    margin-bottom: 10%;
    transform: scale(1.6);
    filter: invert(0%);
    transition: filter 0.125s ease-in-out, transform 0.1s ease-in-out;
}

.bottom-left-ui-container {
    position: fixed;
    bottom: 15px;
    left: 15px;
    z-index: 0;
    display: flex;
}

.bottom-left-ui {
    user-select: none;
    background-color: rgba(185, 185, 185, 0.15);
    color: white;
    border: 1.5px solid rgba(255, 255, 255, 0.1);
    border-radius: 7.5px;
    padding: 6.5px 13px;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
    font-weight: 600;
    letter-spacing: 0.2px;
    text-decoration: none;
    font-size: 13.5px;
    transition: background-color 0.125s ease-in-out, transform 0.1s ease-in-out;
    margin-right: 10px;
    animation: popIn 0.075s ease-out;
}

.bottom-left-ui:hover {
    background-color: rgba(150, 150, 150, 0.4);
    transform: scale(1.05);
}

@media (min-width: 800px) {

    .slideInAnimation,
    .slideInAnimation::after {
        animation-delay: 0s;
        animation-iteration-count: 1;
        animation-duration: 500ms;
        animation-fill-mode: both;
        animation-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
    }

    .slideInAnimation {
        --animation-delay: 0s;
        --animation-duration: 500ms;
        --animation-iterations: 1;
        animation-name: clip-text;

        &::after {
            content: "";
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ffffff;
            transform: scaleX(0);
            transform-origin: 0 50%;
            pointer-events: none;
            animation-name: text-revealer;
        }
    }

    @keyframes clip-text {
        from {
            clip-path: solid(0 100% 0 0);
        }

        to {
            clip-path: solid(0 0 0 0);
        }
    }

    @keyframes text-revealer {

        0%,
        50% {
            transform-origin: 0 50%;
        }

        60%,
        100% {
            transform-origin: 100% 50%;
        }

        60% {
            transform: scaleX(1);
        }

        100% {
            transform: scaleX(0);
        }
    }
}

@media (max-width: 800px) {
    .user-details-container {
        margin-top: 5px;
        margin-right: 0;
        background-size: contain;
    }

    .dashboardHi {
        font-size: 6.5vh;
        width: 95%;
    }

    .dashboardProfileName {
        font-size: 8vh;
        width: 95%;
    }

    .dashboardProfileNameNew {
        font-size: 8vh;
        width: 95%;
    }

    .subverbNew {
        font-size: 5vh;
        width: 100%;
    }

    .user-details-container-center {
        grid-template-columns: repeat(auto-fit, minmax(90%, 1fr));
        margin: 50px auto;
        gap: 25px;
        background-size: contain;
    }

    .centered-button:hover .button-image {
        transform: scale(1.55);
    }

    .button-image {
        margin-right: 7px;
        width: 60px;
        height: 60px;
        margin-bottom: 10%;
        transform: scale(1.5);
        filter: invert(0%);
        transition: filter 0.125s ease-in-out;
    }

    .label1 {
        font-size: 32px;
    }

    .label2 {
        font-size: 18px;
        margin-left: 10px;
    }

    .smallerDashboardMotiveText {
        max-width: inherit;
        font-size: 2vh;
        width: 95%;
    }

    .dashboardMotiveText {
        font-size: 2vh;
        width: 95%;
    }

    .schoolMotiveText {
        font-size: 3vh;
    }

    .centered-button {
        width: 367.5px;
        height: 165px;
        padding: 30px;
        transform: scale(1);
    }

    .centered-button:hover {
        transform: scale(1.05);
        background-color: rgb(255, 255, 255);
    }

    .bottom-left-ui {
        padding: 0;
        font-size: 0;
        display: none;
    }

    .threeBoxDivCollegeDiv {
        display: flex;
        max-width: 95vw;
        flex-direction: column;
        gap: 10px;
    }

}