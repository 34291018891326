.edit-article {
    display: flex;
    margin-left: 12.5%;
    margin-top: 5%;
    justify-content: space-between;
    width: 75%;
}

.title-input {
    width: 100%;
    font-size: 3vw;
    text-align: center;
    font-weight: 900;
    letter-spacing: 0.01vw;
    margin-bottom: 0.5vw;
    border-radius: 1vw;
}

.content-input {
    width: 100%;
    font-size: 1vw;
    font-weight: 700;
    opacity: 0.9;
    max-width: 90%;
    height: 50vh;
    margin-bottom: 1vw;
    overflow-y: auto;
}

.save-button {
    display: block;
    margin-top: 1vw;
    padding: 0.5vw 1vw;
    background-color: var(--buttonColor);
    color: white;
    border: none;
    cursor: pointer;
}

.edit-section {
    width: 47.5%;
    justify-content: center;
    align-items: center;
}

.preview-section {
    width: 47%;
    overflow-y: auto;
}

.markdown-image {
    max-width: 95%;
}

.content-input {
    width: 100%;
    margin-top: 1vw;
    font-size: 1vw;
    font-weight: 700;
    max-width: 100%;
    height: 60vh;
    overflow-y: auto;
    font-family: 'Montserrat', Arial, sans-serif;
    background-color: transparent;
    color: white;
    border: 0px solid;
}

@media (max-width: 800px) {
    .edit-article {
        flex-direction: column;
        margin-left: inherit;
        margin-top: 10%;
        width: 90%;
    }

    .edit-section,
    .preview-section {
        width: 100%;
    }

    .title-input {
        font-size: 8vw;
        text-align: center;
    }

    .content-input {
        text-align: center;
        font-size: 4vw;
    }
}