.friends {
  margin-left: 10px;
  padding: 2rem;
  min-height: 100vh;
}

.friends-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}