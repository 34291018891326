:root {
  --bg-color: #070707;
  --text-color: #ffffff;
  --navBarColor: #000000b6;
  --seventeenBackGroundColor: rgb(17, 17, 17, 0.95);
  --usernameColor1: rgba(255, 255, 255, 0.5);
  --usernameColor2: rgba(255, 255, 255, 0.8);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}

btn {
  font-family: 'Montserrat', Arial, sans-serif;
}

strong {
  font-weight: 900;
  font-size: calc(1em + 0.4px);
}

[theme="light"] {
  --bg-color: rgb(225, 225, 225);
  --text-color: #242424;
  --navBarColor: #ffffffb6;
  --usernameColor1: rgba(56, 56, 56, 0.5);
  --usernameColor2: rgba(56, 56, 56, 0.8) !important;
  --seventeenBackGroundColor: rgba(222, 222, 222, 0.95);
  --invertDarkModeColor: invert(100%);
}

code {
  font-family: 'Montserrat', sans-serif;
}

.forceWhiteLink {
  color: rgb(255, 255, 255) !important;
}

.forceWhiteLink:hover {
  color: rgb(255, 255, 255) !important;
}

.forceWhiteLink:visited {
  color: rgb(255, 255, 255) !important;
}

.forceWhiteLink:active {
  color: rgb(255, 255, 255) !important;
}

a {
  color: rgb(0, 150, 255);
}

a:hover {
  color: rgb(0, 190, 255);
}

a:visited {
  color: rgb(0, 120, 255);
}

a:active {
  color: rgb(178, 40, 0);
}

.updatedScrollBar::-webkit-scrollbar {
  width: 6px;
}

.updatedScrollBar::-webkit-scrollbar-track {
  background: transparent;
}

.updatedScrollBar::-webkit-scrollbar-thumb {
  background-color: #a1a1a1;
  border-radius: 10px;
}

.updatedScrollBar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}



.updatedScrollBarBlack::-webkit-scrollbar {
  width: 6px;
}

.updatedScrollBarBlack::-webkit-scrollbar-track {
  background: transparent;
}

.updatedScrollBarBlack::-webkit-scrollbar-thumb {
  background-color: #333333;
  border-radius: 10px;
}

.updatedScrollBarBlack::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.react-modal {
  z-index: 9999;
  transform-origin: center center;
  position: absolute;
  top: 0;
  left: 0;
  animation-name: modalFadeIn;
  animation-duration: 0.175s;
}

.react-modal.fadeOut {
  animation-name: modalFadeOut;
  animation-duration: 0.15s;
}