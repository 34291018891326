.articleSearchBar {
    font-family: 'Montserrat', sans-serif;
    width: 75%;
    background-color: rgba(50, 50, 50, 0.2);
    color: rgba(255, 255, 255);
    font-size: 17px;
    font-weight: 700;
    border: none;
    height: 20px;
    border-radius: 10px;
    padding: 10px 10px 10px 45px;
    margin-top: 10px;
    transition: background-color 0.3s;
    background-image: url('./Icons/searchIcon.png');
    background-position: 10px center;
    background-repeat: no-repeat;
    background-size: 20px;
    border: 3.5px solid rgba(255, 255, 255, 0.15);
}

.searchOld-results {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
}

.search-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.articleAuthor {
    margin-top: 1.5px;
    position: relative;
    display: inline-flex;
    border-radius: 7.5px;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.125s ease-in-out, transform 0.125s ease-in-out;
    backdrop-filter: blur(5px);
    flex-direction: row-reverse;
    background-color: rgba(234, 234, 234, 0.05);
    padding: 3px 6.25px !important;
}

.articleBlurb {
    max-width: 90%;
    text-align: center;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 7.5px;
    max-lines: 5;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.articleListTitle {
    margin-top: 1vw;
    text-align: center;
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 0.5vw;
}

.articleAuthor:hover {
    background-color: rgba(210, 210, 210, 0.1);
    transform: scale(1.015);
}

.articlesCreatorIcon {
    width: 1vw;
    height: 1vw;
}

.article-title {
    margin: 0;
    font-weight: 700;
    font-size: 1.2em;
}

.article-author {
    margin: 0.5em 0;
    font-weight: 600;
}

.article-text {
    margin: 0.5em 0;
    font-weight: 400;
}