.limitedMoreInfoDiv {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    gap: 25px;
    justify-content: center;
}

.RobloxSearchColumm {
    gap: 10px;
    flex-direction: column
}

.noMaxWidth {
    max-width: inherit !important;
}

.truncateText {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.robloxLimitedHorizontalDiv {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.smallOldSearchValueGap {
    display: flex;
    flex-direction: row;
    gap: 7.5px;
}

.robloxMoreInfoIcon {
    height: 50px;
    width: 50px;
}

.smallerRobloxMore {
    height: 40px;
    width: 40px;
}

.robloxVerticalDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.robloxMoreInfoText1 {
    text-align: left;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
}

.robloxMoreInfoText2 {
    text-align: left;
    font-weight: 800;
    font-size: 22.5px;
}