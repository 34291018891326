.login-input {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

.login {
  max-width: 400px;
  margin: 4rem auto;
  padding: 2rem;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.akr-logo {
  width: 300px;
  height: 125px;
  object-fit: contain;
  align-items: cover;
  margin-bottom: 50px;
  margin-top: -10px;
  margin-left: 25px;
  transition: transform 0.125s ease-in-out;
  transform: scale(0.95);
}

.akr-logo:hover {
  transform: scale(1);
}

.login h2 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.login form {
  display: flex;
  flex-direction: column;
}

.login label {
  text-align: left;
  margin-bottom: 0.5rem;
  color: #ffffff;
}

.email-error {
  color: #ff0000;
  font-size: 0.875rem;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}

.signInDiv {
  justify-self: center;
  justify-content: center;
  align-self: center;
  align-items: center;
}

.login-input {
  width: 100%;
  height: 35px;
  padding: 0.5rem;
  margin-bottom: 1.05rem;
  border: 1px solid #ccc;
  border-radius: 6.5px;
  background-color: #fff;
  color: #3b3b3b;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  box-sizing: border-box;
}

.login-input::placeholder {
  color: #999;
}

.login-button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 0.75rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  margin: 10px 0;
  cursor: pointer;
  border-radius: 7.5px;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  transition: transform 0.1s ease-in-out, background-color 0.1s ease-in-out;
  transform: scale(1);
  box-sizing: border-box;
}

.login-button:hover {
  background-color: #0056b3;
  transform: scale(1.02);
}

.login-separator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;
  position: relative;
}

.login-separator span {
  padding: 0 1rem;
  background-color: #fff;
  z-index: 1;
  position: relative;
  color: #999;
}

.login-separator::before {
  content: "";
  height: 1px;
  width: 100%;
  background-color: #e9ecef;
  position: absolute;
}

.login-others {
  margin-top: 17px;
  text-align: center;
}

.login-others h3 {
  font-size: 1rem;
  margin-bottom: 1rem;
  color: #ffffff;
}

.signInDiv>div {
  margin: 0 auto;
}