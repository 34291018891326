.searchBar2Frame {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 50;
    transition: top 0.15s ease-out;
    gap: 10px;
}

.searchFrame {
    max-width: 90%;
    display: flex;
    justify-content: center;
    align-items: top;
    margin-top: 100px;
    padding: 20px;
}

.searchAkrLogo {
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 50;
    transition: top 0.15s ease-out, transform 0.125s ease-in-out;
}

.searchAkrLogoHover {
    top: 0%;
    transform: translate(-50%, -50%) scale(0);
}

.searchAkrActualLogo {
    height: 125px;
    transform: scale(1.1);
    transition: height 0.125s ease-in-out;
}

.searchAkrActualLogoHover {
    height: 0;
}

.miniProfileName {
    text-align: center;
    font-size: 27.5px;
    font-weight: 900;
}

.robloxLimitedNameNew {
    text-align: center;
    font-size: 25px;
    font-weight: 900;
    max-width: 95%;
}

.mathName {
    text-align: left;
    font-size: 27.5px;
    font-weight: 900;
    overflow-wrap: break-word;
}

.miniProfilePic {
    margin-top: 10px;
    width: 100px;
    height: 100px;
    border-radius: 25%;
    object-fit: cover;
    margin-bottom: 10px;
}

.limitedMiniPic {
    width: 105px !important;
    height: 105px !important;
    border-radius: 17.5px !important;
    background-color: rgba(255, 255, 255, 0.25);
}

.forceNoMarginTop {
    margin-top: inherit !important;
}

.miniProfileUsername {
    margin-top: 1px;
    text-align: center;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
}

mjx-assistive-mml {
    max-width: 100%;
    overflow-wrap: break-word !important;
    word-break: break-all !important;
}

mjx-container {
    max-width: 100%;
    overflow-wrap: break-word !important;
    word-break: break-all !important;
}

mjx-math {
    overflow-wrap: break-word !important;
    word-break: break-all !important;
}

.rowSystemSearch {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.columnSystemSearch {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.miniProfileStatus {
    margin-top: 5px;
    text-align: left;
    font-size: 15px;
    color: var(--usernameColor2);
    margin-bottom: 5px;
}

.widerSocialCard {
    border-radius: 17.5px !important;
    max-width: 90% !important;
}

.v1MiniSocialCard {
    display: flex;
    justify-content: left;
    flex-direction: column;
    align-items: flex-start;
    width: auto;
    background-color: rgba(17, 17, 17, 0.95);
    border: 3.5px solid rgba(255, 255, 255, 0.1);
    border-radius: 25px;
    padding: 10px 20px 10px 20px;
    max-width: 60%;
    transition: transform 0.1s ease-in-out;
}

.forceMarginMax {
    max-width: 100% !important;
}

.shortenedArticleAbout {
    margin-top: -15px;
    max-lines: 5;
    overflow: hidden;
    display: -webkit-box;
    color: var(--usernameColor2);
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.searchUsernameBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
}

.gapSearchResults {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.miniProfileInfo {
    margin-left: 15px;
    justify-content: left;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
}

.v1MiniSocialCard:hover {
    background-color: rgba(193, 193, 193, 0.25);
    transform: scale(1.05);
}

.sidebarSearchTitle {
    font-size: 2vw;
    margin-bottom: 0.25vw;
    font-weight: 900;
    text-align: center;
    letter-spacing: 0.025vw;
    opacity: 0.95;
    max-width: 90%;
    align-self: center;
}

.searchSidebar {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 20px;
    max-width: 30%;
}

.searchResponseText {
    color: rgba(255, 255, 255, 0.85);
    font-weight: 800;
    font-size: 1.5vw;
    margin-bottom: 1.1vw;
    overflow-wrap: break-word;
}

.essayReviewResponseText {
    color: rgba(255, 255, 255, 0.85);
    font-weight: 800;
    font-size: 1.25vw;
    margin-bottom: 1vw;
    overflow-wrap: break-word;
    display: flex;
    flex-direction: column;
    margin: 35px 0 0 0 !important;
}

.searchMainBar {
    flex: 3;
    padding: 20px;
}

.infinateLoadingAnimation {
    animation: progressIndeterminate 0.8s infinite;
}

.essayReviewResponseText h3 {
    color: #bcbcbc;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    font-size: 1vw;
    border-bottom: 2px solid #ffffff;
    padding-bottom: 0.25em;
    font-weight: 600;
}

.essayReviewResponseText ul {
    margin-left: 20px;
    margin: 0;
    list-style-type: disc;
    font-size: 1vw;
}

.essayReviewResponseText li {
    margin-bottom: 0.5em;
    font-weight: 600;
    font-size: 1vw;
}

.essayReviewResponseText p {
    margin-bottom: 0 !important;
    font-size: 1vw;
    font-weight: 600;
}

.essayReviewResponseText h3 {
    margin-bottom: 0 !important;
    font-size: 1vw;
    font-weight: 800;
}

.essayReviewResponseText strong {
    margin-bottom: 0 !important;
    font-size: 1vw;
    font-weight: 800;
}

@keyframes progressIndeterminate {
    from {
        width: 0;
        margin-left: 0;
        margin-right: 100%;
    }

    50% {
        width: 75%;
        margin-left: 0;
        margin-right: 0;
    }

    to {
        width: 0;
        margin-left: 95%;
        margin-right: 0;
    }
}

.searchBar2Frame.hideScrollFrame {
    animation: scrollFadeInOut 0.1s ease-in-out;
    animation-fill-mode: forwards;
    top: 5% !important;
}

@keyframes scrollFadeInOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.searchBar {
    width: 50vw;
    padding: 10px 15px 10px 60px;
    font-size: 27.5px;
    border: none;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.1);
    border: 4px solid rgba(255, 255, 255, 0.15);
    color: #ffffff;
    font-family: 'Montserrat', Arial, sans-serif;
    font-weight: 700;
    background-image: url('./Icons/searchIcon.png');
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 25px;
    transition: background-color 0.125s ease-in-out, transform 0.1s ease-in-out, padding 0.1s ease-in-out, font-size 0.1s ease-in-out, width 0.1s ease-in-out;
}

.searchBarNoMagnifyingGlass {
    width: 50vw;
    padding: 10px 15px 10px 15px;
    font-size: 27.5px;
    border: none;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.1);
    border: 4px solid rgba(255, 255, 255, 0.15);
    color: #ffffff;
    font-family: 'Montserrat', Arial, sans-serif;
    font-weight: 700;
    transition: background-color 0.125s ease-in-out, transform 0.1s ease-in-out, padding 0.1s ease-in-out, font-size 0.1s ease-in-out, width 0.1s ease-in-out;
}

.moreWidthAndHeightSearchBar {
    width: 60vw !important;
    height: 30vh;
}

.searchLoadingDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50vw;
    border-radius: 15px;
    color: #ffffff;
    transition: background-color 0.125s ease-in-out, transform 0.1s ease-in-out, padding 0.1s ease-in-out, font-size 0.1s ease-in-out, width 0.1s ease-in-out;
}

.searchLoadingInsideDiv {
    padding: 1px 15px 10px 60px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 1);
    color: #ffffff;
    overflow: hidden;
    transition: background-color 0.125s ease-in-out, transform 0.1s ease-in-out, padding 0.1s ease-in-out, font-size 0.1s ease-in-out, width 0.1s ease-in-out;
}

.searchBar:focus {
    transform: scale(1.025);
    outline: none;
}

.searchBarNoMagnifyingGlass:focus {
    transform: scale(1.025);
    outline: none;
}

.searchResultBarNoMagnifyingGlass {
    width: 85vw;
    font-size: 20px;
    padding: 7.5px 15px;
    height: 25px !important;
}

@keyframes vibrate {
    0% {
        transform: translate(2px, 2px) rotate(0deg);
    }

    10% {
        transform: translate(-2px, -4px) rotate(-1deg);
    }

    20% {
        transform: translate(-4px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(4px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(2px, -2px) rotate(1deg);
    }

    50% {
        transform: translate(-2px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-4px, 2px) rotate(0deg);
    }

    70% {
        transform: translate(4px, 2px) rotate(-1deg);
    }

    80% {
        transform: translate(2px, -2px) rotate(1deg);
    }

    90% {
        transform: translate(-2px, 4px) rotate(0deg);
    }

    100% {
        transform: translate(4px, 4px) rotate(-1deg);
    }
}

.redColorSearch {
    background-color: rgba(213, 54, 54, 0.15);
    animation: vibrate 0.3s linear 30;
}

.searchResult {
    top: 12.5%;
}

.searchResultBar {
    width: 85vw;
    font-size: 20px;
    padding: 7.5px 7.5px 7.5px 50px;
    height: 25px !important;
}

@media (max-width: 650px) {
    .v1MiniSocialCard {
        max-width: inherit !important;
    }

    .rateLimitPopup {
        width: 75%;
    }

    .searchFrame {
        margin-top: 60px;
    }

    .searchAkrActualLogo {
        height: 125px;
        transform: scale(0.9);
    }

    .searchResultBar {
        width: 75vw;
    }

    .searchResultBarNoMagnifyingGlass {
        width: 75vw;
    }

    .searchBar {
        width: 67.5vw !important;
    }

    .searchBarNoMagnifyingGlass {
        width: 67.5vw !important;
    }

    .searchResponseText {
        font-size: 22.5px;
    }

    .searchResponseText p {
        text-align: center;
    }

    .essayReviewResponseText {
        font-size: 20px;
    }

    .essayReviewResponseText p {
        text-align: center;
    }

    .searchMainBar {
        padding: 0 0 0 0;
    }

    mjx-container {
        font-size: inherit !important;
    }

    .miniProfileName {
        font-size: 22.5px;
    }

    .essayReviewResponseText h3 {
        font-size: 20px;
    }

    .essayReviewResponseText p {
        font-size: 15px;
    }

    .essayReviewResponseText strong {
        font-size: 15px;
    }

    .essayReviewResponseText li {
        font-size: 15px;
    }

    .robloxLimitedNameNew {
        font-size: 22.5px;
    }
}