.TutoringSession {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
}

.centerHomeSreenDemonTutors {
    height: 90vh;
}

.TutoringTitle {
    font-size: 2.25vw;
    align-self: center;
    font-weight: 900;
    letter-spacing: 0.0075vw;
    max-height: 80%;
}

.TutoringMEHSName {
    font-size: 1.25vw;
    align-self: center;
    font-weight: 900;
    max-height: 80%;
    opacity: 0.8;
}

.socialStatusContainerV1 {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.tutoringSelectionTitle {
    margin-top: 0.25vw;
    color: var(--usernameColor2);
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    font-size: 0.85vw;
    font-weight: 700;
    margin-bottom: 1.25rem;
}

.modalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.modalContainerImage {
    width: 90px;
    margin-bottom: 10px;
    transition: transform 0.1s ease-in-out;
}

.modalContainerImage:hover {
    transform: scale(1.05);
}

.TutoringStudentBox {
    display: flex;
    flex-wrap: wrap;
    width: 75vw;
    gap: 0.25vw 0.75vw;
    justify-content: center;
    align-items: center;
}

.subjectCards {
    font-size: 1vw;
    padding: 0.4vw 0.65vw;
    font-weight: 850;
    place-items: center;
    user-select: none;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
    display: inline-flex;
    border-radius: 7.5px;
    align-items: center;
    cursor: pointer;
    transform: scale(1);
    transition: transform 0.1s ease-in-out;
    color: #ffffff;
    font-size: 1.05vw;
    background-color: rgba(17, 17, 17, 0.95);
    border: 3px solid rgba(255, 255, 255, 0.1);
}

.subjectCards:hover {
    transition: transform 0.1s ease-in-out;
    transform: scale(1.035);
}

.centerModalTutoringSubjects {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5vw;
}

.demonTutorPortrait {
    object-fit: cover;
    width: 15vw;
    height: auto;
    border-radius: 1vw;
    margin-top: 0.25vw;
    margin-bottom: 0.3vw;
}

.schoolColorOpace {
    background-color: rgba(110, 110, 110, 0.3) !important;
}

.studentCard {
    background-color: rgba(110, 110, 110, 0.3) !important;
    border: 3px solid rgba(255, 255, 255, 0.1);
    padding: 0.7vw;
    width: 12.5vw;
    border-radius: 19px;
    transition: transform 0.1s ease-in-out;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
}

.studentCard img {
    width: 9vw;
    height: auto;
    border-radius: 1vw;
    margin-top: 0.25vw;
    margin-bottom: 0.3vw;
}

.studentCardName {
    color: rgb(255, 255, 255);
    font-size: 1.5vw;
    margin-bottom: 0.25vw;
    font-weight: 900;
    text-align: center;
}

.studentCardBlurb {
    color: var(--usernameColor2);
    font-size: 0.7vw;
    margin-bottom: 0.2vw;
    text-align: center;
}

.studentCardContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 1vw;
    justify-content: center;
}

.iconDemonTutor {
    height: 2vw !important;
}

.studentCard:hover {
    transition: transform 0.1s ease-in-out;
    transform: scale(1.035);
}

.greenPlay {
    background-color: rgba(37, 185, 91, 0.65) !important;
}

.bottomRightUiV2 {
    font-weight: 800 !important;
    position: reltive;
    display: block !important;
    border-radius: 0.4vw;
    align-items: center;
    cursor: pointer;
    font-size: 1vw;
    background-color: rgba(175, 75, 75, 0.4);
    padding: 0.6vw 2.5vw !important;
    color: rgba(255, 255, 255, 1) !important;
    height: 1.25vw;
    transition: background-color 0.15s ease-in-out, transform 0.1s ease-in-out;
    margin-top: 0.4vw !important;
    text-align: center !important;
}

.bottomRightUiV3 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800 !important;
    position: relative;
    border-radius: 7.5px;
    cursor: pointer;
    font-size: 17.5px;
    background-color: rgba(255, 255, 255, 0.35);
    text-shadow: rgb(20, 20, 20) 0 0 15px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 5px 25px !important;
    color: rgba(255, 255, 255, 0.9) !important;
    transition: background-color 0.15s ease-in-out, transform 0.1s ease-in-out;
    margin-top: 0.4vw !important;
    text-align: center !important;
}

.demonDescriptionText {
    margin-top: 0.1vw;
    margin-left: 0.5vw;
    color: var(--usernameColor2);
    font-size: 0.7vw;
    margin-bottom: 0.25vw;
    max-width: 90%;
}

.demonTutorModalBottomRight {
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 1000;
    display: flex;
    gap: 10px;
}

#demonTutors12 {
    background-size: fill;
    background-position: center;
}

.bottomRightUiV3:hover {
    background-color: rgba(255, 255, 255, 0.7);
    transform: scale(1.07);
}

.bottomRightUiV2:hover {
    background-color: rgba(255, 255, 255, 0.7);
    transform: scale(1.07);
}

@media (max-width: 1200px) {
    .bottomRightUiV2 {
        font-size: 1.75vw;
        padding: 0.5vw 3vw !important;
        height: 2vw;
        border-radius: 1w;
        transition: background-color 0.15s ease-in-out, transform 0.1s ease-in-out;
        margin-top: 0.4vw !important;
        text-align: center !important;
    }
}

@media (max-width: 650px) {
    .TutoringStudentBox {
        width: 90%;
        gap: 0.75vw 2.25vw;
    }

    #demonTutors12 {
        background-image: none;
        height: inherit !important;
    }

    .gameDetailsGameDescription {
        font-size: 4vw;
    }

    .demontutorModalSystem {
        flex-direction: column-reverse !important;
    }

    .TutoringSession {
        margin: auto;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        width: 100%;
        margin-top: 3vw;
    }

    .TutoringTitle {
        margin-top: 7.5vw;
        font-size: 10vw !important;
        max-width: 85%;
        text-align: center;
    }

    .bottomRightUiV2 {
        font-size: 6vw;
        padding: 0.6vw 2.5vw !important;
        height: 7.5vw;
        border-radius: 1.5vw;
        transition: background-color 0.15s ease-in-out, transform 0.1s ease-in-out;
        margin-top: 0.4vw !important;
        text-align: center !important;
    }

    .bottomRightUiV3 {
        font-size: 4vw;
        transition: background-color 0.15s ease-in-out, transform 0.1s ease-in-out;
        margin-top: 0.4vw !important;
        text-align: center !important;
    }

    .tutoringSelectionTitle {
        font-size: 3.75vw;
        max-width: 85%;
    }

    .studentCard {
        max-width: inherit !important;
        padding: 5vw;
        width: 80%;
        flex: none;
        border-radius: 15px;
    }

    .studentCard img {
        width: 50vw;
        height: auto;
        border-radius: 5vw;
        margin-bottom: 0.3vw;
    }

    .demonTutorPortrait {
        width: 32vw;
        border-radius: 3vw;
    }

    .studentCardName {
        font-size: 10vw;
        margin-bottom: 0.25vw;
        font-weight: 900;
    }

    .studentCardBlurb {
        font-size: 4.75vw;
        margin-bottom: 0.2vw;
    }

    .studentCardContainer {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        gap: 3vw;
    }

    .iconDemonTutor {
        height: 2vw !important;
    }

    .biggerSocialButton {
        font-size: 6vw !important;
        padding: 1.75vw 3vw !important;
    }
}