.notification-dropdown {
  margin-right: 15px;
  border-radius: 7.5px;
  align-items: center;
  cursor: pointer;
  padding: 10px 20px;
  background-color: rgba(236, 236, 236, 0.035);
  transition: background-color 0.15s ease-in-out;
  text-decoration: none;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  transition: transform 0.1s ease-in-out;
  backdrop-filter: blur(5px);
}

.ntfc-container {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  text-align: center;
  justify-content: center;
  margin-bottom: -0.25rem;
}

.bell-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
  margin-right: 20px;
}

.notification-small-text {
  color: var(--usernameColor2);
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 1.25rem;
}

.notification-big-title {
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-size: 25px;
  font-weight: 900;
}

.notification-item {
  width: 400px;
  background-color: rgba(100, 100, 100, 0.2);
  padding: 10px;
  border-radius: 15px;
  transform: scale(0.97);
  display: flex;
  align-items: center;
  margin-bottom: 3px;
  transition: transform 0.1s ease-in-out;
}

.notificationButtons {
  height: 110px !important;
}

.notificationButtons .notification-image {
  margin-top: -27px !important;
}

.notificationButtons .notification-contnr-title {
  margin-top: -27px !important;
}

.notification-item:hover {
  background-color: rgba(193, 193, 193, 0.25);
  transform: scale(1);
}

.notification-item:last-child {
  margin-bottom: 0;
}

.notificationImageDropDown {
  width: 75px;
  height: 75px;
  max-height: 150px;
  max-width: 150px;
  margin-left: 7.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  border-radius: 25%;
  object-fit: fit;
  margin-right: 17.5px;
}

.notification-contnr {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.notification-contnr-morewidth {
  max-height: 650px !important;
  margin-bottom: 5px !important;
  padding-right: 3px;
}

.notification-contnr-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 2px;
  max-width: 295px;
}

.notification-contnr-message {
  color: var(--usernameColor1);
  font-weight: 700;
  font-size: 13px;
  margin-bottom: 10px;
}

.centeredContainer {
  display: flex;
  align-items: center;
  position: relative;
}

@media (max-width: 800px) {
  .notification-item {
    width: 350px;
  }

  .notification-contnr-morewidth {
    margin-bottom: 5px !important;
  }

  .notification-contnr-title {
    max-width: 240px;
  }
}